<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="reports"
            :loading="isLoading">

            <template v-slot:item.actions="{ item }">
                <v-btn small icon to="/admin-panel/reports/edit">
                    <v-icon>edit</v-icon>
                </v-btn>
                <v-btn small icon to="/admin-panel/reports/view">
                    <v-icon>visibility</v-icon>
                </v-btn>
            </template>

        </v-data-table>
    </div>
</template>

<script>
export default {
    
    props: {
        isLoading: Boolean,
    }, // props

    data: () => ({
        headers: [
            {
                text: 'Report Name',
                value: 'reportName',
                sortable: false,
            },
            {
                text: 'Permissions',
                value: 'permissions',
                sortable: false,
            },
            {
                value: 'actions',
                sortable: false,
            },
        ],
        reports: [],
    }), // data

    mounted() {
        this.reports = this.createDummyReports();
    }, // mounted

    methods: {
        createDummyReports() {
            return Array.from({ length: 10 }, (_, index) => ({
                reportName: `Report #${index + 1}`,
                permissions: 'Admin, Salesman, Employee, ...',
            }));
        },
    }, // methods
}
</script>
