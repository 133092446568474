var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.reports,
          loading: _vm.isLoading
        },
        scopedSlots: _vm._u([
          {
            key: "item.actions",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      small: "",
                      icon: "",
                      to: "/admin-panel/reports/edit"
                    }
                  },
                  [_c("v-icon", [_vm._v("edit")])],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      small: "",
                      icon: "",
                      to: "/admin-panel/reports/view"
                    }
                  },
                  [_c("v-icon", [_vm._v("visibility")])],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }