//-----------------------| Search Module |-------------------//

/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import searchClient from "@/services/azsearch.service";
import api from "@/api";
import Vue from 'vue'
import { cancelToken } from '../../../api'

const state = {
  cancel: undefined,
  moduleName: 'search',
  facets: [],
  autoCompleteData: [],
  products: {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    totalPages: 0,
    totalCount: 0
  },
  selectedImage: undefined,
  currentProductId: 0,
  autocompleteRequest: {
    fuzzy: false,
    highlights: true,
    searchValue: null,
  },
  productRequest: {
    pageSize: 8,
    pageNumber: 1,
    searchValue: null,
    sortField: 'unitPrice',
    sortDirection: null,
    category: null,
    facets: [],
    leftMostPage: 1,
  },
  recommendedProductRequest: {
    pageSize: 4,
    pageNumber: 1,
    category: null,
    subType: null,
    style: null,
    searchValue: null,
    sortField: 'unitPrice',
    sortDirection: null,
    facets: []
  },
  recommendedProducts: {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    totalPages: 0,
    totalCount: 0
  },
  isSearching: false,
  searchValue: null,
}

const getters = {
  facets: state => {
    return state.facets;
  },
  getProducts: state => {
    return state.products;
  },
  request: state => {
    return state.productRequest
  },
  getProduct: state => state.product,
  getAutoCompleteData: state => state.autoCompleteData,
  recommendedProducts: state => state.recommendedProducts,

  getProductCategories: () => {
    let productCategoriesStr = localStorage.getItem('fb.productCategories');
    if (!productCategoriesStr) return [];
    return JSON.parse(productCategoriesStr);
  },

  getIsSearching: state => state.isSearching,
}

const actions = {

  get({ state, commit }) {
    commit("SET_IS_SEARCHING", true);

    if (state.cancel != undefined) {
      state.cancel();
    }

    var request = Object.assign({}, state.productRequest);
    state.productRequest.facets = [];
    state.products.items = [];

    console.log("request", request);
    request.pageNumber = parseInt(request.pageNumber);

    return api.post(`${state.moduleName}/results`, request, {
      cancelToken: new cancelToken(function executor(c) {
        state.cancel = c;
      }),
    }).then(result => {
      commit("SET_PRODUCTS", result);
      commit("SET_IS_SEARCHING", false);
      return result;
    });
  },

  getAutocomplete({ state, commit }) {
    api.get(`${state.moduleName}/autocomplete`, { params: state.autocompleteRequest }).then(result => commit("SET_AUTOCOMPLETE_DATA", result));
  },

  getByTagName({ state, commit }, { stockItemTagName, searchRequest }) {
    return api.post(`${state.moduleName}/tag/${stockItemTagName}`, searchRequest)
      .then(result => {
        commit("SET_PRODUCTS", result);
      });
  },

  getProductCategoriesRequest({ state, commit }) {
    return api.get(`${state.moduleName}/categories`)
      .then(result => {
        let productCategoriesJson = result.data;
        let productCategoriesStr = JSON.stringify(productCategoriesJson);
        localStorage.setItem('fb.productCategories', productCategoriesStr);
      });
  },

  getPromoted({ state, commit }) {
    return api.post(`${state.moduleName}/promoted`);
  },

  getRecommendedProducts({ state, commit }) {
    return api.post(`${state.moduleName}/recommended`, state.recommendedProductRequest)
      .then(result => commit("SET_RECOMMENDED_PRODUCTS", result));
  },

  getRecommendedProductsByCategory({ state, commit }, category) {
    return api.post(`${state.moduleName}/recommended/${category}`, state.recommendedProductRequest)
      .then(result => commit("SET_RECOMMENDED_PRODUCTS", result));
  },
}

const mutations = {
  SET_RESULTS(state, data) {
    state.searchResults = data;
  },
  SET_PAGE_NUMBER(state, data) {
    console.log("SET page change------->", data);
    state.productRequest.pageNumber = data;
  },
  SET_SORT_DIRECTION(state, data) {
    state.productRequest.sortDirection = data;
    console.log("SORT DIRECTION ----->", data);
    state.productRequest.pageNumber = 1;
    state.productRequest.leftMostPage = 1;
  },
  SET_PAGE_SIZE(state, data) {
    state.productRequest.pageSize = data;
  },
  SET_AUTOCOMPLETE_DATA(state, data) {
    state.autoCompleteData = data.data;
  },
  SET_SEARCH_VALUE(state, data) {
    state.productRequest.searchValue = data;
    state.autocompleteRequest.searchValue = data;
    console.log("SET_SEARCH_VALUE ----->", data);
    state.productRequest.pageNumber = 1;
    state.productRequest.leftMostPage = 1;
  },
  SET_PRODUCTS(state, data) {
    state.products = data.data || [];
    Vue.set(state.products, 'items', data.data.items);
    Vue.set(state.productRequest, 'facets', data.data.facets);
  },
  SET_RECOMMENDED_PRODUCTS(state, data) {
    state.products = data.data || [];
    Vue.set(state.recommendedProducts, 'items', data.data.items);
  },

  SET_RECOMMENDED_CATEGORY(state, data) {
    state.recommendedProductRequest.category = data;
    if (state.recommendedProductRequest.category != "Beer" &&
      state.recommendedProductRequest.category != "Spirits" &&
      state.recommendedProductRequest.category != "Wine") {
      state.recommendedProductRequest.style = null;
      state.recommendedProductRequest.subType = null;
    }
  },
  SET_RECOMMENDED_SUBTYPE(state, data) {
    state.recommendedProductRequest.subType = data;
    state.recommendedProductRequest.style = null;
  },
  SET_RECOMMENDED_STYLE(state, data) {
    state.recommendedProductRequest.style = data;
    state.recommendedProductRequest.subType = null;
  },

  SET_FACETS(state, data) {
    Vue.set(state.productRequest, 'facets', data);
    console.log("SET_FACETS ----->", data);
      
    if (state.productRequest.facets.some(x => x.options.some(o => o.value)))
      state.productRequest.searchValue = null;


  },
  SET_FACETS_FORMAT(state, data) {
    Vue.set(state.productRequest, 'facets', data);
    console.log("SET_FACETS_FORMAT ----->", data);

    if (state.productRequest.facets.some(x => x.options.some(o => o.value)))
      state.productRequest.searchValue = null;

  },
  SET_PRODUCT_CATEGORY(state, data) {
    state.productRequest.category = data;
    Vue.set(state.productRequest, 'category', data);
  },

  ADD_FACET_OPTION(state, { facetName, facetOption }) {
    if (!state.productRequest.facets)
      state.productRequest.facets = [];

    let facet = state.productRequest.facets.find(currentFacet => currentFacet.facet == facetName);

    // If no facet with that name was found, create a new facet
    if (!facet) {
      facet = {
        facet: facetName,
        multiSelect: false,
        orderValue: 0,
        showAll: false,
      };

      state.productRequest.facets.push(facet);
    }

    // If the facet was just created or existed but didn't have any options, create new options
    if (!facet.options)
      facet.options = [];

    facet.options.push(facetOption);
  },

  SET_IS_SEARCHING(state, value) { // value: boolean
    state.isSearching = value;
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
