<template v-if="id">
  <div class="px-sm-4 px-md-12">

    <!-- client side header -->
    <div v-if="isClient" class="mb-4">

      <v-list-item>
        <v-list-item-icon class="mr-4">
          <v-icon size="38">account_box</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <h5 class="mb-0">({{account.internalId}}) {{account.customerName}}</h5>
          </v-list-item-title>
          <v-list-item-subtitle v-if="location">
            <h6 class="font-weight-regular mb-0">{{location.locationName}}</h6>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </div><!-- client side header -->

    <!-- admin side header -->
    <v-row v-if="!isClient">
      <v-col cols="12" class="layout justify-start mt-0 mx-0">
        <v-icon class="mr-2" large>account_box</v-icon>
        <v-text-field
          class="tab-input-title"
          :disabled="isView"
          v-model="account.customerName"/>
      </v-col>
    </v-row><!-- admin side header -->

    <v-row class="mb-4" v-if="!isClient">
      <v-col class="pt-0 form-top-back" cols="12">
        <router-link
          class="pt-4 d-block font-weight-medium"
          to="/admin-panel/accounts"
        >Back to Accounts</router-link>
      </v-col>
    </v-row>
    <v-card>
      <v-tabs
        :vertical="verticalTabs"
        :centered="!verticalTabs"
        :center-active="!verticalTabs"
        v-model="activeTab"
      >
        <v-tab class="d-flex flex-column flex-md-row justify-md-start justify-center">
          <v-icon :left="verticalTabs">info</v-icon>Overview
        </v-tab>
        <v-tab class="d-flex flex-column flex-md-row justify-md-start justify-center">
          <v-icon :left="verticalTabs">poll</v-icon>Orders
        </v-tab>
        <v-tab
          v-if="!isClient"
          class="d-flex flex-column flex-md-row justify-md-start justify-center">
          <v-icon :left="verticalTabs">account_circle</v-icon>Team
        </v-tab>
        <v-tab class="d-flex flex-column flex-md-row justify-md-start justify-center">
          <v-icon :left="verticalTabs">location_on</v-icon>Addresses
        </v-tab>
        <v-tab class="d-flex flex-column flex-md-row justify-md-start justify-center">
          <v-icon :left="verticalTabs">account_box</v-icon>Licenses
        </v-tab>
        <v-tab
          v-if="!isClient"
          class="d-flex flex-column flex-md-row justify-md-start justify-center">
          <v-icon :left="verticalTabs">poll</v-icon>Surveys
        </v-tab>
        <v-tab
          v-if="!isClient"
          class="d-flex flex-column flex-md-row justify-md-start justify-center">
          <v-icon :left="verticalTabs">poll</v-icon>Forms
        </v-tab>
        <v-tab-item>
          <v-card flat>
            <account-basic-info :account="account" :readOnly="readOnly" :isClient="isClient"></account-basic-info>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <account-orders :account="account" :readOnly="readOnly"></account-orders>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item v-if="!isClient">
          <v-card flat>
            <v-card-text>
              <account-collaboration :account="account" :readOnly="readOnly"></account-collaboration>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <account-addresses :account="account" :readOnly="readOnly" :isClient="isClient"></account-addresses>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <account-licenses :account="account" :readOnly="readOnly"></account-licenses>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item v-if="!isClient">
          <v-card flat>
            <v-card-text>
              <account-surveys :account="account" :data="surveys" :readOnly="readOnly"></account-surveys>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item v-if="!isClient">
          <v-card flat>
            <v-card-text>
              <account-forms-tab></account-forms-tab>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import AccountBasicInfo from "./AccountBasicInfo.vue";
import AccountCollaboration from "./AccountCollaboration.vue";
import AccountAddresses from "./AccountAddress/AccountAddresses.vue";
import AccountLicenses from "./AccountLicense/AccountLicenses.vue";
import AccountSurveys from "./AccountSurveys.vue";
import AccountFormsTab from "./AccountFormsTab.vue";
import AccountOrders from "./AccountOrders/AccountOrders.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  props: ["accountId", "readOnly", "isClient"],

  data() {
    return {
      isView: false,
      verticalTabs: true,
      activeTab: 0,
    };
  },
  mounted() {
    if (this.$router.currentRoute.path.includes("order-history"))
      this.activeTab = 1;
    else this.activeTab = 0;

    this.isView = this.$route.path.includes("view") || this.readOnly;
    this.id = this.$route.params.id || this.accountId;
    if (this.id) {
      this.setCurrentAccountId(this.id);
      this.getAccountById().then(() => {
        this.setCustomerId(this.account.customerId);
        this.getLocationById(this.account.locationId);

        // set selected tab to licenses
        let tab = this.$route.query.tab;
        if (tab && tab == 'licenses') {
          this.activeTab = 3;
        }
      });
      this.getSurveysFromApi();
    }

    // watch for window dimensions (for tabs responsiveness)
    this.$nextTick(() => window.addEventListener("resize", () => this.verticalTabs = window.innerWidth >= 960));
    this.verticalTabs = window.innerWidth >= 960;
  },
  methods: {
    ...mapActions({
      getAccountById: "accounts/getById",
      updateAccount: "accounts/update",
      getSurveysFromApi: "accounts/getSurveysFromApi",
      getFormByIdFromApi: "accounts/getFormByIdFromApi",
      getSubmissionByIdFromApi: "accounts/getSubmissionByIdFromApi",
      getLocationById: 'catalog/getLocationById',
    }),
    ...mapMutations({
      setCurrentAccountId: "accounts/SET_CURRENT_ACCOUNT_ID",
      setCustomerId: "order/SET_REQUEST_CUSTOMER_ID",
    }),
  },
  computed: {
    ...mapGetters({
      account: "accounts/getAccount",
      surveys: "accounts/getSurveys",
      forms: "accounts/getForms",
      formById: "accounts/getFormById",
      submissions: "accounts/getSubmissions",
      submissionById: "accounts/getSubmissionById",
      location: 'catalog/getLocation',
    }),
  },
  components: {
    AccountBasicInfo,
    AccountCollaboration,
    AccountAddresses,
    AccountLicenses,
    AccountSurveys,
    AccountFormsTab,
    AccountOrders,
  },
  watch: {
    $route() {
      if (this.$router.currentRoute.path.includes("order-history"))
        this.activeTab = 1;
      else this.activeTab = 0;
    },
  },
};
</script>
