<template>
  <div class="lightening-deals-wrap section-gap pb-0">
    <v-container grid-list-xl>
      <div class="sec-wrap primary py-4 px-6">
        <div class="layout align-center c-layout justify-space-between pa-4">
          <div class="sec-title">
            <h2 class="white--text mb-0 text-xxl">{{ title }}</h2>
          </div>
          <span
            v-if="!isLoading && items && items.length != 0"
            class="white--text text-sm text-uppercase font-weight-medium cursor-pointer"
            @click="$emit('view-all', title)"
          >
            View all
          </span>
        </div>
      </div>
      <div class="shop-card-gap px-6">
        <!-- loading -->
        <v-progress-linear v-if="isLoading" indeterminate color="white" />

        <!-- items loaded and not empty -->
        <v-layout v-if="!isLoading && items && items.length != 0" row wrap>
            <v-flex xs12 sm6 md6 lg3 xl3 v-for="(item, index) in items" :key="index">
              <div class="emb-card box-shadow-md pa-6 white">
                    <a href="javascript:void(0)" class="text-decoration-none" @click="$emit('item-clicked', item)">
                        <img class="d-block mx-auto"
                          style="height: 280px"
                          alt="Product"
                          :src="item.thumbnail">
                    </a>
                  <div class="emb-card-content pt-4">
                    <h5 class="h5-fixed-lines-3">{{item.name}}</h5>
                    <div class="d-flex">
                        <p class="text-muted cursor-pointer" @click="$emit('category-clicked', item.categories)">{{item.categories}}</p>
                    </div>
                    <p class="accent--text mb-0">$ {{$numformat.currencyFormat(item.unitPrice)}}</p>
                  </div>
              </div>
            </v-flex>
        </v-layout>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  props: ["title", "items", "isLoading"],
};
</script>
