<template>
  <div class="table-responsive">
    <v-data-table
      :headers="headers"
      :items="paginatedList.items"
      :server-items-length="paginatedList.totalCount"
      :options.sync="listViewOptions"
      :loading="isLoading"
      :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }">

      <template v-slot:item.action="{ item }">
        <v-btn small icon class="text--primary" :to="'/admin-panel/account-edit/' + item.internalId">
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn small icon class="primary--text" :to="'/admin-panel/account-view/' + item.internalId">
          <v-icon>visibility</v-icon>
        </v-btn>
      </template>
      
    </v-data-table>
  </div>
</template>

<script>
export default {

  props: ['paginatedList', 'isLoading', 'searchParams'],

  data() {
    return {
      headers: [
        {
          text: "Account Number",
          sortable: true,
          value: "internalId"
        },
        {
          text: "Description",
          sortable: true,
          value: "customerName"
        },
        {
          text: "Action",
          sortable: false,
          value: "action"
        }
      ],

      listViewOptions: {
        page: 1,
        itemsPerPage: 10,
      },
    };
  }, // data

  mounted() {
    this.listViewOptions.page = this.searchParams.pageNumber;
    this.listViewOptions.itemsPerPage = this.searchParams.pageSize;
  }, // mounted

  watch: {
    listViewOptions: {
      handler() {
        this.searchParams.pageNumber = this.listViewOptions.page;
        this.searchParams.pageSize = this.listViewOptions.itemsPerPage;

        let sortField = this.listViewOptions.sortBy[0];
        let sortDesc = this.listViewOptions.sortDesc[0];

        if (sortField) {
          this.searchParams.sortField = sortField;
          this.searchParams.sortDirection = sortDesc? 'DESC' : 'ASC';
        }

        this.$emit('search-params-changed', this.searchParams);
      },
    },
  }, // watch
};
</script>
