<template>
  <div>
    <div class="d-flex mb-6">
      <router-link class="font-weight-medium" to="/admin-panel/trading-messages"
        >Back</router-link
      >
    </div>

    <div class="center-x" v-if="isLoading">
      <v-skeleton-loader type="text" width="100%" />
    </div>
    <v-card>
      <v-card-title>Trading Message</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              disabled
              label="Correlation Id"
              v-model="tradingMessage.x12correlationId"
              
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Message Process Status"
              v-model="tradingMessage.x12messageProcessStatus"
              disabled
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              label="Received Status"
              v-model="tradingMessage.x12messageReceivedStatus"
              disabled
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Received Time"
              v-model="tradingMessage.x12messageReceivedTimestamp"
              disabled
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Reveived Validation Status"
              v-model="tradingMessage.x12messageReveivedValidationStatus"
              disabled
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Message Acknowledgment Status"
              v-model="tradingMessage.x12messageSentAcknowledgmentStatus"
              disabled
            />
          </v-col>
          
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      tradingMessage: {},
      isLoading: false,
      messageId:undefined
    };
  },
  async beforeMount() {
    this.messageId = this.$route.params.id;
    await this.getCurrentMessage();
  },
  methods: {
    ...mapActions({
      getMessageById: "tradingPartner/getMessagesById",
    }),
    async getCurrentMessage() {
      try {
          this.isLoading = true;
          this.tradingMessage = await this.getMessageById(this.messageId);
      } catch (error) {
        this.$snotify.error(`Could load trading message. ${error}`, {
          closeOnClick: false,
          pauseOnHover: false,
          timeout: 4000,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>