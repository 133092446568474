import Admin from 'Container/Adminpanel.vue';

const Reports = () => import('Views/AdminPanel/Reports/Reports.vue');
const Invoice = () => import('Views/AdminPanel/Invoices.vue');
const AdminAccount = () => import('Views/AdminPanel/Account.vue');
const AdminAccounts = () => import('Views/AdminPanel/Accounts/Accounts.vue');
const AdminAccountsForm = () => import('Views/AdminPanel/Accounts/AccountsForm.vue');
const AdminOrders = () => import('Views/AdminPanel/Orders/Orders.vue');
const AdminOrderView = () => import('Views/AdminPanel/Orders/OrderForm.vue');
const AdminOrderEdit = () => import('Views/AdminPanel/Orders/OrderForm.vue');
const AdminProducts = () => import('Views/AdminPanel/Products/Products.vue');
const AdminProductsForm = () => import('Views/AdminPanel/Products/ProductsForm.vue');
const AdminStockGroups = () => import('Views/AdminPanel/StockGroups/StockGroups.vue');
const PrivacyPolicy = () => import('Views/AdminPanel/Products/ProductsForm.vue');
const Suppliers = () => import('Views/AdminPanel/Suppliers/Suppliers.vue');
const SupplierForm = () => import('Views/AdminPanel/Suppliers/SupplierForm.vue')
const People = () => import('Views/AdminPanel/People/People.vue');
const PersonForm = () => import('Views/AdminPanel/People/PersonForm.vue');
const Tags = () => import('Views/AdminPanel/Tags/Tags.vue');
const TagForm = () => import('Views/AdminPanel/Tags/TagForm.vue');
const LicenseTypes = () => import('Views/AdminPanel/LicenseTypes/LicenseTypes.vue');
const ReportDetails = () => import('Views/AdminPanel/Reports/ReportDetails.vue');
const Brand = () => import('Views/AdminPanel/Brands/Brand.vue');
const Partners = () => import('Views/AdminPanel/TradingPartners/TradingPartner.vue');
const PartnerForm = () => import('Views/AdminPanel/TradingPartners/TradingPartnerForm.vue');
const TradingMessages = () => import('Views/AdminPanel/TradingMessages/TradingMessage.vue');
const TradingForm = () => import('Views/AdminPanel/TradingMessages/TradingMessageForm.vue');
const Indexer = () => import('Views/AdminPanel/Indexer/Indexer.vue');

// Guards
import { permissionGuardNoAccount } from './guards';

export default {
	path: '/admin-panel',
	component: Admin,
	redirect: '/admin-panel/reports',
	beforeEnter: permissionGuardNoAccount,
	children: [
		{
			path: 'reports',
			component: Reports,
			name: 'Reports'
		},
		{
			path: 'reports/add',
			component: ReportDetails,
			name: 'ReportDetails',
		},
		{
			path: 'reports/edit',
			component: ReportDetails,
			name: 'ReportDetails',
		},
		{
			path: 'reports/view',
			component: ReportDetails,
			name: 'ReportDetails',
		},
		{
			path: 'brand',
			component: Brand,
			name: 'Brand',
		},
		{
			path: 'partners',
			component: Partners,
			name: 'Partners',
		},
		{
			path: 'partner-add',
			component: PartnerForm,
			name: 'PartnerAdd',
		},
		{
			path: 'partner-edit/:id',
			component: PartnerForm,
			name: 'PartnerEdit',
		},
		{
			path: 'trading-messages',
			component: TradingMessages,
			name: 'TradingMessages',
		},
		{
			path: 'trading-messages-view/:id',
			component: TradingForm,
			name: 'TradingForm',
		},
		{
			path: 'invoices',
			component: Invoice,
			name: 'Invoice'
		},
		{
			path: 'products',
			component: AdminProducts,
			name: 'AdminProducts'
		},
		{
			path: 'product-add',
			component: AdminProductsForm,
			name: 'AdminProductsAdd'
		},
		{
			path: 'product-edit/:id',
			component: AdminProductsForm,
			name: 'ProductsEdit',
		},
		{
			path: 'product-view/:id',
			component: AdminProductsForm,
			name: 'ProductsView',
		},
		{
			path: 'stock-groups',
			component: AdminStockGroups,
			name: 'AdminStockGroups',
		},
		{
			path: 'accounts',
			component: AdminAccounts,
			name: 'AdminAccounts'
		},
		{
			path: 'account-add',
			component: AdminAccountsForm,
			name: 'AdminAccountsAdd',
		},
		{
			path: 'account-edit/:id',
			component: AdminAccountsForm,
			name: 'AccountsEdit',
		},
		{
			path: 'account-view/:id',
			component: AdminAccountsForm,
			name: 'AccountsView',
		},
		{
			path: 'account/profile',
			component: AdminAccount,
			name: 'UserProfile',
		},
		{
			path: 'orders',
			component: AdminOrders,
			name: 'Orders',
		},
		{
			path: 'order-edit/:id',
			component: AdminOrderEdit,
			name: 'OrderEdit',
		},
		{
			path: 'order-view/:id',
			component: AdminOrderView,
			name: 'OrderView',
		},
		{
			path: 'suppliers',
			component: Suppliers,
			name: 'Suppliers',
		},
		{
			path: 'supplier-edit/:id',
			component: SupplierForm,
			name: 'SupplierForm',
		},
		{
			path: 'supplier-view/:id',
			component: SupplierForm,
			name: 'SupplierView',
		},
		{
			path: 'people',
			component: People,
			name: 'People',
		},
		{
			path: 'person-add',
			component: PersonForm,
			name: 'PersonAdd',
		},
		{
			path: 'person-edit/:id',
			component: PersonForm,
			name: 'PersonEdit',
		},
		{
			path: 'person-view/:id',
			component: PersonForm,
			name: 'PersonView',
		},
		{
			path: 'tags',
			component: Tags,
			name: 'Tags',
		},
		{
			path: 'tags/:stockItemTagId',
			component: TagForm,
			name: 'TagForm',
		},
		{
			path: 'license-types',
			component: LicenseTypes,
			name: 'LicenseTypes',
		},
		{
			path: 'privacy-policy',
			component: PrivacyPolicy,
			name: 'PrivacyPolicy1'
		},
		{
			path: 'indexer',
			component: Indexer,
			name: 'Indexer'
		},
	]
}
