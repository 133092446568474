<template>
  <div class="admin-panel">

    <vue-snotify></vue-snotify>
    
    <v-navigation-drawer v-model="drawer" app class="primary" :right="rtlLayout" width="230">
      <div class="site-logo py-4 mb-12">
        <router-link to="/" class="d-block text-center">
          <img alt="site-logo" height="34" :src="appLogo" width="70%" />
        </router-link>
      </div>
      <v-list dense class="admin-menu-wrap">
        <template v-for="(menuItem , key) in adminPanelMenus">
          <template v-if="menuItem.children == null">
            <v-list-item
              :key="key"
              :to="menuItem.path"
              :class="menuItem.active"
              @click="updateSidebarMenu(menuItem)"
            >
              <v-list-item-action class="ma-0">
                <v-icon>{{menuItem.icon}}</v-icon>
              </v-list-item-action>
              <v-list-item-content class="py-0 text-left pl-3">
                <v-list-item-title>{{$t(menuItem.name)}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-else>
            <v-list-group
              :key="key"
              no-action
              v-model="menuItem.active"
              :prepend-icon="menuItem.icon"
              append-icon="keyboard_arrow_down"
            >
              <template v-slot:activator>
                <v-list :to="menuItem.path">
                  <v-list-item-content class="py-0 text-left pl-3">
                    <v-list-item-title>
                      <span>{{ $t(menuItem.name) }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list>
              </template>
              <template v-if="menuItem.children">
                <v-list-item
                  @click="updateSidebarMenu(menuItem)"
                  v-for="(subItem,index) in menuItem.children"
                  v-bind:key="index"
                  :to="subItem.path"
                >
                  <v-list-item-content>
                    <v-list-item-title class="white--text">{{ $t(subItem.name) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list-group>
          </template>
        </template>
      </v-list>
    </v-navigation-drawer>

    <!-- header toolbar -->
    <v-app-bar color="white" dark app class="admin-panel-toolbar">
      <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="primary ml-0" fab dark small></v-app-bar-nav-icon> -->
      <v-btn class="mx-2" fab dark small color="primary" @click.stop="drawer = !drawer">
        <v-icon dark>menu</v-icon>
      </v-btn>
      <div class="d-inline-flex align-center">
        <div class="options mr-2">
          <emb-lang></emb-lang>
        </div>

        <v-toolbar-title class="mr-2" @click="isSwitchingLocation = true">
          <a href="javascript:void(0)" class="white--text font-weight-regular">{{currentLocation.locationName}}</a>
          <v-icon dark>arrow_drop_down</v-icon>
        </v-toolbar-title>

        <div class="notifications">
          <emb-user-block :userBlockItems="userBlockItems"></emb-user-block>
        </div>
      </div>
    </v-app-bar><!-- header toolbar -->

    <!-- main content -->
    <v-content>
      <v-container fill-height container-fluid>
        <v-layout justify-center align-center class="py-6 px-sm-2">
          <v-flex>
            <router-view></router-view>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content><!-- main content -->

    <!-- logout dialog -->
    <v-dialog persistent max-width="300" v-model="isLoggingOut">
      <v-card class="pa-3">
        <v-card-text class="d-flex flex-column align-center">
          <p>Logging out</p>
          <v-progress-circular width="2" indeterminate />
        </v-card-text>
      </v-card>
    </v-dialog><!-- logout dialog -->

    <!-- switch location dialog -->
    <v-dialog max-width="500" v-model="isSwitchingLocation">
      <v-card>
        <v-card-text>

          <!-- current location -->
          <div v-if="currentLocation">
            <h5 class="font-weight-regular pt-6 mb-3">Your current location is</h5>

            <v-card class="mb-6">
              <v-card-title>{{currentLocation.locationName}}</v-card-title>
            </v-card>
          </div><!-- current location -->

          <h5 class="font-weight-regular mb-3">Select another location</h5>

          <!-- other locations -->
            <div v-for="location in locations" :key="location.locationId">
              <v-hover v-slot:default="{ hover }">
                <v-card :elevation="hover? 6 : 2" :class="{ 'on-hover': hover }" class="hoverable mb-3" @click="onLocationSelected(location)">
                  <v-card-title>{{location.locationName}}</v-card-title>
                </v-card>
              </v-hover>
            </div><!-- other locations -->

        </v-card-text>
      </v-card>
    </v-dialog><!-- switch location dialog -->

    <!-- switching location loading dialog -->
    <v-dialog persistent max-width="300" v-model="isLoadingLocation">
        <v-card class="pa-3">
            <v-card-text class="d-flex flex-column align-center">
                <p>Switching location...</p>
                <v-progress-circular width="2" indeterminate />
            </v-card-text>
        </v-card>
    </v-dialog><!-- switching location loading dialog -->

  </div>
</template>

<script>
import UserBlock from "Components/Layouts/Header/UserBlock";
import Lang from "Components/Layouts/Header/Lang";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      appLogo: process.env.VUE_APP_LOGO,
      drawer: null,
      isLoggingOut: false,
      isSwitchingLocation: false,
      isLoadingLocation: false,
      userBlockItems: [
        {
          icon: "account_circle",
          title: "Profile",
          onClick: () => this.$router.push('/admin-panel/account/profile'),
        },
        {
          icon: "power_settings_new",
          title: "LogOut",
          onClick: () => {
            this.isLoggingOut = true;
            this.$msal.logout();
          },
        }
      ],
    };
  }, // data

  computed: {
    ...mapGetters(["adminPanelMenus", "rtlLayout"]),
    ...mapGetters({
      locations: 'catalog/getLocations',
      currentPerson: 'session/getPerson',
      currentLocation: 'session/getLocation',
    }),
  }, // computed

  mounted() {
    this.getLocations();
  }, // mounted

  methods: {
    ...mapActions({
      getLocations: 'catalog/requestGetLocations',
      switchLocation: 'session/switchLocation',
    }),

    onLocationSelected(location) {
      this.isSwitchingLocation = false;
      this.isLoadingLocation = true;

      this.switchLocation(location.locationId)
      .then(() => this.$router.go());
    },

    updateSidebarMenu(currentMenu) {
      this.$store.dispatch("changeActiveMenu", currentMenu);
    },
  }, // methods

  components: {
    embUserBlock: UserBlock,
    embLang: Lang
  }, // components

};
</script>
