<template>
    <div>

        <!-- search bar -->
        <v-card class="pa-4 mb-4">
            <v-row no-gutters>
                <v-col cols="12" sm="3" class="d-flex align-center justify-center justify-sm-start">
                    <v-icon class="mr-2">person</v-icon>
                    <h5 class="ma-0">People</h5>
                    <v-btn class="d-sm-none" color="error" icon to="/admin-panel/person-add">
                        <v-icon>add</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12" sm="6" class="d-flex justify-center align-center">
                    <v-text-field class="mr-4" label="Search People" v-model="searchValue" @change="executeSearch"/>
                    <v-btn color="primary" @click="executeSearch">Search</v-btn>
                </v-col>
                <v-col cols="12" sm="3" class="d-flex justify-end align-center">
                    <v-btn class="d-none d-sm-flex" color="error"  to="/admin-panel/person-add">
                        Add user
                        <v-icon>add</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card><!-- search bar -->

        
        <!-- filter people by location switch -->
        <div>
            <div class="d-flex justify-end">
                <v-switch
                    class="ma-0 pa-0"
                    label="Show All People"
                    v-model="searchParams.showAllPeople"
                    @change="onShowAllPeopleSwitched"/>
            </div>
            <p v-if="!searchParams.showAllPeople" class="text-right">Showing people from {{currentLocation.locationName}}</p>
        </div><!-- filter people by location switch -->

        <people-items-list-view
            :isLoading="isLoading"
            :paginatedList="peoplePaginatedList"
            :searchParams="searchParams"
            v-on:search-params-changed="onSearchParamsChanged">
        </people-items-list-view>

    </div>
</template>

<script>
import PeopleItemsListView from './PeopleItemsListView.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    data() {
        return {
            isLoading: true,
            searchValue: undefined,
        };
    }, // data

    computed: {
        ...mapGetters({
            currentLocation: 'session/getLocation',
            peoplePaginatedList: 'people/getPeople',
            searchParams: 'people/getSearchParams',
        }),
    }, // computed

    mounted() {
        let resetSearchParams = this.$route.query.resetSearchParams;
        if (resetSearchParams && resetSearchParams.toString() == true.toString()) this.resetSearchParams();

        this.searchValue = this.searchParams.searchValue;
        if (this.searchParams.showAllPeople) this.getPeople().then(() => this.isLoading = false);
        else this.getPeopleByLocation().then(() => this.isLoading = false);
    }, // mounted

    methods: {
        ...mapMutations({
            setSearchValue: 'people/SET_SEARCH_PARAM_SEARCH_VALUE',
            setSearchParams: 'people/SET_SEARCH_PARAMS',
            resetSearchParams: 'people/RESET_SEARCH_PARAMS',
        }),

        ...mapActions({
            getPeople: 'people/get',
            getPeopleByLocation: 'people/getByLocation',
        }),

        executeSearch() {
            this.isLoading = true;
            this.setSearchValue(this.searchValue);
            
            if (this.searchParams.showAllPeople) this.getPeople().then(() => this.isLoading = false);
            else this.getPeopleByLocation().then(() => this.isLoading = false);
        },

        onSearchParamsChanged(searchParams) {
            this.isLoading = true;
            this.setSearchParams(searchParams);
            this.setSearchValue(this.searchValue);

            if (this.searchParams.showAllPeople) this.getPeople().then(() => this.isLoading = false);
            else this.getPeopleByLocation().then(() => this.isLoading = false);
        },

        onShowAllPeopleSwitched() {
            this.isLoading = true;
            
            if (this.searchParams.showAllPeople) this.getPeople().then(() => this.isLoading = false);
            else this.getPeopleByLocation().then(() => this.isLoading = false);
        },
    }, // methods

    components: {
        PeopleItemsListView,
    }, // components
}
</script>
