<template>
    <div class="px-sm-4 px-md-12" v-if="supplier">
        <v-row>
            <v-col cols="12" class="layout justify-start mt-0 mx-0">
                <v-icon class="mr-2" large>people</v-icon>
                <v-text-field
                    :disabled="isView"
                    class="tab-input-title"
                    v-model="supplier.supplierName"
                    required/>
            </v-col>
        </v-row>
        <v-row class="mb-4">
            <v-col class="pt-0 form-top-back" cols="12">
                <router-link
                    class="pt-4 d-block font-weight-medium"
                    to="/admin-panel/suppliers">
                    Back to Suppliers
                </router-link>
            </v-col>
        </v-row>
        <v-card class="mb-3">
            <v-tabs :vertical="verticalTabs" :centered="!verticalTabs" :center-active="!verticalTabs">
                <v-tab class="d-flex flex-column flex-md-row justify-md-start justify-center">
                    <v-icon :left="verticalTabs">info</v-icon>
                    Overview
                </v-tab>
                <v-tab class="d-flex flex-column flex-md-row justify-md-start justify-center">
                    <v-icon :left="verticalTabs">local_post_office</v-icon>
                    Postal Address
                </v-tab>
                <v-tab class="d-flex flex-column flex-md-row justify-md-start justify-center">
                    <v-icon :left="verticalTabs">local_shipping</v-icon>
                    Delivery
                </v-tab>
                <v-tab-item>
                    <supplier-overview-tab :selectedSupplier="supplier"></supplier-overview-tab>
                </v-tab-item>
                <v-tab-item>
                    <supplier-bank-account-tab :selectedSupplier="supplier"></supplier-bank-account-tab>
                </v-tab-item>
                <v-tab-item>
                    <supplier-postal-address-tab :selectedSupplier="supplier"></supplier-postal-address-tab>
                </v-tab-item>
                <v-tab-item>
                    <supplier-delivery-tab :selectedSupplier="supplier"></supplier-delivery-tab>
                </v-tab-item>
            </v-tabs>
        </v-card>

        <div class="d-flex justify-end" v-if="!isView">
            <v-btn :loading="isPerformingOperation" :disabled="isPerformingOperation" color="error" large @click="onClickSave">Save</v-btn>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SupplierOverviewTab from './SupplierOverviewTab.vue';
import SupplierBankAccountTab from './SupplierBankAccountTab.vue';
import SupplierPostalAddressTab from './SupplierPostalAddressTab.vue';
import SupplierDeliveryTab from './SupplierDeliveryTab.vue';

export default {
    data: () => ({
        isEdit: false,
        isView: false,
        supplierId: undefined,
        verticalTabs: true,
        isPerformingOperation: false,
    }), // data

    computed: {
        ...mapGetters({
            supplier: 'suppliers/getCurrentSupplier',
        }),
    }, // computed

    beforeMount() {
        this.isEdit = this.$route.path.includes('edit');
        this.isView = this.$route.path.includes('view');
        this.supplierId = this.$route.params.id;

        if (this.supplierId) {
            this.requestGetSupplierById(this.supplierId);
        }
    }, // beforeMount

    mounted() {
        // watch for window dimensions (for tabs responsiveness)
        this.$nextTick(() => {
            window.addEventListener('resize', () => {
                this.verticalTabs = window.innerWidth >= 960;
            });
        });
        this.verticalTabs = window.innerWidth >= 960;
    }, // mounted

    methods: {
        ...mapActions({
            requestGetSupplierById: 'suppliers/requestGetSupplierById',
            requestUpdateSupplier: 'suppliers/requestUpdateSupplier'
        }),

        onClickSave() {
            if (this.isEdit) {
                this.supplier.supplierId = Number(this.supplier.supplierId);
                this.isPerformingOperation = true;

                this.requestUpdateSupplier({
                    supplier: this.supplier,
                    onSuccessCallback: () => {
                        this.isPerformingOperation = false;

                        this.$snotify.success("The supplier has been updated", {
                            closeOnClick: false,
                            pauseOnHover: false,
                            timeout: 2000
                        });
                    },
                    onErrorCallback: (error) => {
                        console.log(error);
                        this.isPerformingOperation = false;

                        this.$snotify.success("The supplier has been updated", {
                            closeOnClick: false,
                            pauseOnHover: false,
                            timeout: 2000
                        });
                    },
                });
            }
        },
    }, // methods

    components: {
        SupplierOverviewTab,
        SupplierBankAccountTab,
        SupplierPostalAddressTab,
        SupplierDeliveryTab,
    }, // components
}
</script>
