<template>
  <div>
    <v-card class="pa-4 mb-4">
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="3"
          class="d-flex align-center justify-center justify-sm-start"
        >
          <v-icon class="mr-2">mdi-handshake</v-icon>
          <h5 class="ma-0">Partners</h5>
        </v-col>
        <v-col cols="12" sm="6" class="d-flex justify-center align-center">
          <v-text-field
            class="mr-4"
            label="Search partners"
            v-model="searchValue"
            @change="executeSearch"
          />
          <v-btn @click="executeSearch" color="primary">Search</v-btn>
        </v-col>
        <v-col cols="12" sm="3" class="d-flex justify-end align-center">
          <v-btn
            class="d-none d-sm-flex"
            color="error"
            to="/admin-panel/partner-add"
          >
            Add New
            <v-icon>add</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <partner-list-view
      :isLoading="isLoading"
      :partnersPaginatedList="partnersPaginatedList"
      v-on:delete-partners-request="deletePartnersRequest"
      :searchParams="searchParams"
      v-on:search-params-changed="onSearchParamsChanged"
    ></partner-list-view>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import PartnerListView from "./TradingPartnerListView.vue";

export default {
  data() {
    return {
      isLoading: true,
      searchValue: undefined,
      partnersPaginatedList: {},
    };
  },
  components: {
    PartnerListView,
  },
  mounted() {
    this.searchValue = this.searchParams.searchValue;
    this.getPartnersRequest();
  },
  computed: {
    ...mapGetters({
      searchParams: "tradingPartner/getSearchParams",
    }),
  },
  methods: {
    ...mapMutations({
      setSearchValue: 'tradingPartner/SET_SEARCH_PARAM_SEARCH_VALUE',
      setSearchParams: 'tradingPartner/SET_SEARCH_PARAMS',
      resetSearchParams: 'tradingPartner/RESET_SEARCH_PARAMS',
    }),
    ...mapActions({
      getPartners: "tradingPartner/get",
      deletePartners: "tradingPartner/delete",
      createPartner: "tradingPartner/post",
    }),
    async getPartnersRequest() {
      this.isLoading = true;
      this.partnersPaginatedList = await this.getPartners();
      console.log("this.partnersPaginatedList", this.partnersPaginatedList);
      this.isLoading = false;
    },
    async deletePartnersRequest(id) {
      this.isLoading = true;

      try {
        await this.deletePartners(id);
        this.$snotify.success("The partner has been deleted", {
          closeOnClick: false,
          pauseOnHover: false,
          timeout: 2000,
        });
        this.getPartnersRequest();
      } catch (error) {
        this.$snotify.error(`Could delete partner. ${error}`, {
          closeOnClick: false,
          pauseOnHover: false,
          timeout: 4000,
        });
        this.isLoading = false;
      }
    },

    async executeSearch() {
      this.isLoading = true;
      this.partnersPaginatedList = await this.getPartners({
        searchValue: this.searchValue,
      });
      this.isLoading = false;
    },
    async createPartnerRequest(partner) {
      this.isLoading = true;
      await this.createPartner(partner);
      this.getPartnersRequest();
      this.isLoading = false;
    },
    onSearchParamsChanged(searchParams) {
      this.isLoading = true;
      this.setSearchParams(searchParams);
      this.setSearchValue(this.searchValue);
      this.getPartnersRequest();
    },
  },
};
</script>