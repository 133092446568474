<template>
  <div class="table-responsive">
    <v-data-table
      :headers="headers"
      :items="partnersPaginatedList.items"
      :server-items-length="partnersPaginatedList.totalCount"
      :options.sync="listViewOptions"
      :loading="isLoading"
      :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }"
    >
      <!-- TODO: Create two route for the edit and the view partners -->
      <template v-slot:item.action="{ item }">
        <v-btn
          small
          icon
          class="primary--text"
          :to="'/admin-panel/partner-edit/' + item.x12tradingPartnerId"
        >
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn
          small
          icon
          class="primary--text"
          @click="showDeletePartnerDialog(item.x12tradingPartnerId)"
        >
          <v-icon>delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <YesNoDialog ref="yesNoDialog" />
  </div>
</template>

<script>
import YesNoDialog from "@/components/Dialogs/YesNoDialog";
export default {
  props: ["isLoading", "partnersPaginatedList", "searchParams" ],
  data() {
    return {
      listViewOptions: {
        page: 1,
        itemsPerPage: 10,
      },
      headers: [
        {
          text: "Partner Id",
          sortable: true,
          value: "x12tradingPartnerId",
        },
        {
          text: "Company  Id",
          sortable: true,
          value: "companyId",
        },
        {
          text: "Partner description",
          sortable: true,
          value: "x12tradingPartnerDescription",
        },
        {
          text: "Partner qualifier",
          sortable: true,
          value: "x12interchangeTradingPartnerQualifier",
        },
        {
          text: "Partner Identifier",
          sortable: true,
          value: "x12interchangeTradingPartnerIdentifier",
        },
        {
          text: "Partner Group Identifier",
          sortable: true,
          value: "x12interchangeTradingPartnerGroupIdentifier",
        },
        {
          text: "Partner Type Id",
          sortable: true,
          value: "x12interchangeTradingPartnerTypeId",
        },
        {
          sortable: false,
          value: "action",
        },
      ],
    };
  },

  mounted() {
    this.listViewOptions.page = this.searchParams.pageNumber;
    this.listViewOptions.itemsPerPage = this.searchParams.pageSize;
  }, // mounted

  methods: {
    showDeletePartnerDialog(partner) {
      this.$refs.yesNoDialog
        .open({
          title: "Remove partner",
          message: "Are you sure you want to remove this partner ",
        })
        .then(() => this.$emit("delete-partners-request", partner));
    },
  },
  components: {
    YesNoDialog,
  },

  watch: {
    listViewOptions: {
      handler() {
        this.searchParams.pageNumber = this.listViewOptions.page;
        this.searchParams.pageSize = this.listViewOptions.itemsPerPage;

        let sortField = this.listViewOptions.sortBy[0];
        let sortDesc = this.listViewOptions.sortDesc[0];

        if (sortField) {
          this.searchParams.sortField = sortField;
          this.searchParams.sortDirection = sortDesc ? "DESC" : "ASC";
        }

        this.$emit("search-params-changed", this.searchParams);
      },
    },
  }, // watch
};
</script>

<style>
</style>
