<template>
  <div class="fixed-header-wrap">

    <!-- expired license warning -->
    <div v-if="isAnyLicenseExpired"
      class="d-flex justify-center align-center pa-1"
      style="background-color: #F44336">

      <v-icon dark class="mr-1">warning</v-icon>

      <h6 class="font-weight-regular ma-0 pa-0">
        <span class="mr-2" style="color: white">One of your licenses is expired or your account is on hold!</span>
      </h6>

      <h6 class="font-weight-regular ma-0 pa-0">
        <router-link to="/account/profile?tab=licenses">View my licenses</router-link>
      </h6>
    </div><!-- expired license warning -->

    <div class="header-wrap primary px-4">
      <v-container grid-list-xl>
        <v-layout align-center justify-space-between row ma-0>
          <div class="site-logo d-inline-block">
            <router-link to="/">
              <img alt="site-logo" height="85" :src="appLogo" width="245" />
            </router-link>
          </div>
          <div>
            <div class="menu-alignment">
              <emb-menu :menus="menus"></emb-menu>
            </div>
            <div class="layout align-left responsive-menu">
              <v-btn icon dark @click="toggleMobileSidebar" class="toggle-btn">
                <i class="material-icons">menu</i>
              </v-btn>
            </div>
          </div>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Menu from "./Menu";

export default {
  props: ["menus"],

  data() {
    return {
      appLogo: process.env.VUE_APP_LOGO,
    };
  },

  computed: {
    ...mapGetters({
      isAnyLicenseExpired: 'session/isAnyLicenseExpired',
    }),
  },

  components: {
    embMenu: Menu,
  },

  methods: {
    toggleMobileSidebar() {
      this.$store.dispatch("toggleSidebar", true);
    },
  },
};
</script>