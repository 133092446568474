var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex" }, [
      _c(
        "p",
        {
          staticClass: "cursor-pointer font-weight-bold",
          on: {
            click: function($event) {
              return _vm.$router.go(-1)
            }
          }
        },
        [_vm._v("Back")]
      )
    ]),
    _c(
      "section",
      [
        _c(
          "v-card",
          { staticClass: "mb-4" },
          [
            _c(
              "v-card-text",
              [
                _c("v-text-field", {
                  attrs: { label: "Report Name", disabled: _vm.isView }
                }),
                _c(
                  "div",
                  { staticClass: "mb-6" },
                  [
                    _c("p", { staticClass: "ma-0 pa-0" }, [
                      _vm._v("Permissions")
                    ]),
                    _c(
                      "v-list-item",
                      { attrs: { dense: "", disabled: _vm.isView } },
                      [
                        _c("v-checkbox", {
                          attrs: { label: "Admin", "hide-details": "" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { attrs: { dense: "", disabled: _vm.isView } },
                      [
                        _c("v-checkbox", {
                          attrs: { label: "Salesman", "hide-details": "" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { attrs: { dense: "", disabled: _vm.isView } },
                      [
                        _c("v-checkbox", {
                          attrs: { label: "Employee", "hide-details": "" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { attrs: { dense: "", disabled: _vm.isView } },
                      [
                        _c("v-checkbox", {
                          attrs: { label: "System User", "hide-details": "" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.isAdd || _vm.isEdit
                  ? _c("drag-and-drop", { attrs: { multiple: true } })
                  : _vm._e()
              ],
              1
            ),
            _c(
              "v-card-actions",
              [
                _c("v-spacer"),
                _vm.isAdd
                  ? _c("v-btn", { attrs: { color: "primary" } }, [
                      _vm._v("Save")
                    ])
                  : _vm._e(),
                _vm.isEdit
                  ? _c("v-btn", { attrs: { color: "primary" } }, [
                      _vm._v("Edit")
                    ])
                  : _vm._e(),
                _vm.isEdit
                  ? _c("v-btn", { attrs: { color: "error" } }, [
                      _vm._v("Cancel")
                    ])
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _vm.isView
      ? _c(
          "section",
          [
            false
              ? _c(
                  "v-card",
                  [
                    _c("v-card-title", [_vm._v(" Report name ")]),
                    _c("v-card-text", [
                      _c("span", [_vm._v("View report here")])
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-layout",
              { staticClass: "emb-card mb-6" },
              [
                _c(
                  "app-card",
                  {
                    attrs: {
                      customClasses: "pa-6",
                      colClasses: "xl12 lg12 md12 xs12 sm12",
                      heading: "Transaction History",
                      removePanel: true,
                      viewMore: true,
                      disableNotification: true,
                      settings: true
                    }
                  },
                  [_c("buy-or-sell")],
                  1
                )
              ],
              1
            ),
            false
              ? _c(
                  "v-layout",
                  { staticClass: "emb-card mb-6" },
                  [
                    _c(
                      "app-card",
                      {
                        attrs: {
                          colClasses: "xl12 lg12 md12 xs12 sm12",
                          contentCustomClass: "pa-6"
                        }
                      },
                      [_c("tabs-and-table")],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }