<template>
    <div>
        <admin-panel-header
            title="Orders"
            icon="local_shipping"
            :refreshButton="true"
            @refresh="onRefresh"
            @search="onSearch">
        </admin-panel-header>

        <orders-list-view
            :paginatedList="ordersPaginatedList"
            :isLoading="isLoading"
            :searchParams="searchParams"
            @search-params-changed="onSearchParamsChanged">
        </orders-list-view>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import AdminPanelHeader from '../AdminPanelHeader';
import OrdersListView from './OrdersListView';

export default {

    data: () => ({
        isLoading: false,
        searchValue: undefined,
    }), // data

    computed: {
        ...mapGetters({
            ordersPaginatedList: 'ordersAdmin/getOrders',
            searchParams: 'ordersAdmin/getSearchParams',
        }),
    }, // computed

    mounted() {
        this.searchValue = this.searchParams.searchValue;
    }, // mounted

    methods: {
        ...mapMutations({
            setSearchValue: 'ordersAdmin/SET_SEARCH_PARAM_SEARCH_VALUE',
            setSearchParams: 'ordersAdmin/SET_SEARCH_PARAMS',
            resetSearchParams: 'ordersAdmin/RESET_SEARCH_PARAMS',
        }),

        ...mapActions({
            getOrders: 'ordersAdmin/get',
        }),

        executeSearch() {
            this.isLoading = true;
            this.setSearchValue(this.searchValue);
            this.getOrders().finally(() => this.isLoading = false);
        },

        onRefresh() {
            this.executeSearch();
        },

        onSearch(searchValue) {
            this.searchValue = searchValue;
            this.executeSearch();
        },

        onSearchParamsChanged(searchParams) {
            this.setSearchParams(searchParams);
            this.executeSearch();
        },
    }, // methods

    components: {
        AdminPanelHeader,
        OrdersListView,
    }, // components
}
</script>
