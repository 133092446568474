/* eslint-disable */
// Sidebar Routers
export const menus = [
   {
      path: '/home',
      name: "message.home",
      icon: "home",
      type: "sub_menu",
      children: null
   },
   {
      path: '/products',
      name: "message.categories",
      icon: 'party_mode',
      type: 'sub_menu',
      children: [
         {
            path: '/products/wine',
            children_menus: null,
            name: 'message.wine'
         },
         {
            path: '/products/beer',
            children_menus: null,
            name: 'message.beer'
         },
         {
            path: '/products/spirits',
            children_menus: null,
            name: 'message.spirits'
         },
         {
            path: '/products/mixers',
            children_menus: null,
            name: 'message.mixer'
         },
         {
            path: '/products/seltzer',
            children_menus: null,
            name: 'message.seltzer'
         },
         {
            path: '/products/mead',
            children_menus: null,
            name: 'message.mead'
         }
      ],
   },
   {
      path: '/products/top',
      name: "message.mytopproducts",
      type: "sub_menu",
      children: null
   },
   {
      path: '/account/profile',
      name: "message.myaccount",
      icon: 'party_mode',
      type: 'sub_menu',
      children: [
         {
            path: '/account/order-history',
            children_menus: null,
            name: 'message.myorders'
         }
      ],
   },
   {
      path: '/contact',
      name: "message.contactUs",
      icon: 'perm_contact_calendar',
      children: null
   },
   {
      path: '/admin-panel/reports',
      name: "message.adminPanel",
      icon: 'perm_identity',
      children: null
   },
]

export const clientPanelMenusNoAccount = [
   {
      path: '/admin-panel/reports',
      name: "message.adminPanel",
      icon: 'perm_identity',
      children: null,
   },
]

export const adminPanelMenus = [
   {
      name: "message.reports",
      icon: 'bar_chart',
      path: "/admin-panel/reports",
      active: false,
      children: null
   },
   {
      name: "message.accounts",
      icon: 'account_box',
      path: "/admin-panel/accounts",
      active: false,
      children: null
   },
   {
      name: "Orders",
      icon: 'local_shipping',
      path: "/admin-panel/orders",
      active: false,
   },
   {
      name: "People",
      icon: 'person',
      path: "/admin-panel/people",
      active: false,
      children: null
   },
   {
      name: "Brand",
      icon: 'hourglass_full',
      path: "/admin-panel/brand",
      active: false,
      children: null
   },
   {
      name: "Trading Partners",
      icon: 'mdi-handshake',
      path: "/admin-panel/partners",
      active: false,
      children: null
   },
   {
      name: "Trading Messages",
      icon: 'mdi-forum ',
      path: "/admin-panel/trading-messages",
      active: false,
      children: null
   },
   {
      name: "message.products",
      icon: 'shopping_cart',
      path: "/admin-panel/products",
      active: false,
      children: null
   },
   {
      name: 'Stock Groups',
      icon: 'group_work',
      path: "/admin-panel/stock-groups",
   },
   {
      name: "Suppliers",
      icon: 'people',
      path: "/admin-panel/suppliers",
      active: false,
      children: null
   },
   {
      name: "Tags",
      icon: 'label',
      path: "/admin-panel/tags",
      active: false,
      children: null
   },
   {
      name: "License Types",
      icon: 'credit_card',
      path: "/admin-panel/license-types",
      active: false,
      children: null
   },
   {
      name: "Indexer",
      icon: 'cached',
      path: "/admin-panel/indexer",
   },
   {
      name: "message.goToSite",
      icon: 'home',
      path: '/home',
      active: false,
      children: null
   },
]
