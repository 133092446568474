<template>
    <div>

        <AdminPanelHeader
            title="Indexer"
            icon="cached"
            :searchBar="false"/>

        <h5 class="font-weight-regular mb-6">
            Click the button below to re-generate the indexes for the products. <br>
            Generating the indexes will cache all of the products.
        </h5>

        <v-layout justify-center class="mb-6">
            <v-btn color="accent" :disabled="isConnectingToIndexer || isConnectionFailed || taskRunning" @click="onRegenerateIndexesClicked">
                Re-generate indexes
            </v-btn>
        </v-layout>

        <div v-if="isConnectingToIndexer">
            <p class="text-center">
                Please wait. Connecting to Indexer...
                <v-progress-circular indeterminate size="28" class="ml-2"/>
            </p>
        </div>
        
        <div v-if="isConnectionFailed">
            <v-layout justify-center>
                <v-card width="500">
                    <v-card-text>
                        <h2 class="text-center error--text">Error!</h2>
                        <h5 class="text-center font-weight-regular mb-4">Could not connect to the indexer</h5>
                        <v-layout justify-center>
                            <v-btn color="primary" @click="onRefreshPageClicked">
                                Refresh Page
                            </v-btn>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-layout>
        </div>

        <div v-if="taskRunning">
            <h5 class="text-center mb-6">
                Re-generating indexes
                <v-progress-circular indeterminate class="ml-2"/>
            </h5>

            <h6>Re-indexed products ({{totalCurrentlyIndexed}} of {{totalToIndex}})</h6>
            <IndexedProductsTable :indexedProducts="indexedProducts"/>
        </div>

        <v-dialog v-model="showSuccessDialog" max-width="400" persistent>
            <v-card class="pt-5">
                <v-card-text>
                    <div class="d-flex justify-center mb-3">
                        <v-icon size="64" color="green">check_circle</v-icon>
                    </div>
                    <h4 class="text-center" style="color: green">Indexing Succeeded!</h4>
                    <h5 class="text-center ma-0 font-weight-regular">
                        Indexed <b>{{totalToIndex}}</b> products
                    </h5>
                </v-card-text>
                <v-card-actions>
                    <v-layout justify-end>
                        <v-btn color="primary" @click="showSuccessDialog = false">
                            Ok
                        </v-btn>
                    </v-layout>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showErrorDialog" persistent scrollable>
            <v-card>
                <v-card-title>Indexing failed!</v-card-title>
                <v-divider/>

                <v-card-text class="py-4">
                    {{errorMessage}}
                </v-card-text>

                <v-divider/>
                <v-card-actions>
                    <v-layout justify-end>
                        <v-btn color="primary" @click="showErrorDialog = false">
                            Close
                        </v-btn>
                    </v-layout>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <YesNoDialog ref="yesNoDialog"/>
    </div>
</template>

<script>
/* eslint-disable */

import AdminPanelHeader from '../AdminPanelHeader.vue';
import IndexedProductsTable from "./IndexedProductsTable.vue";
import YesNoDialog from '../../../components/Dialogs/YesNoDialog.vue';

import api from '../../../apiClient';
import hubs from '../../../hubs';

export default {

    data: () => ({
        isConnectingToIndexer: false,
        isConnectionFailed: false,
        errorMessage: '',
        indexedProducts: {
            items: [],
            to: 0,
            totalCount: 0,
        },
        showSuccessDialog: false,
        showErrorDialog: false,
        status: undefined,
        taskRunning: false,
    }), // data

    mounted() {
        this.init();
    }, // mounted

    computed: {
        totalCurrentlyIndexed() {
            return this.indexedProducts.to;
        },

        totalToIndex() {
            return this.indexedProducts.totalCount;
        }
    }, // computed

    methods: {
        async init() {
            try {
                console.log("Connecting to indexer...");
                this.isConnectingToIndexer = true;
                this.isConnectionFailed = false;
                await hubs.indexer.connect();
                console.log("Connected to indexer!");
                this.watchIndexerEvents();
            }
            catch (err) {
                this.isConnectionFailed = true;
            }
            finally {
                this.isConnectingToIndexer = false;
            }
        },

        onRefreshPageClicked() {
            window.location.reload();
        },

        async onRegenerateIndexesClicked() {
            try {
                await this.$refs.yesNoDialog.open({
                    title: "Re-index?",
                    message: "Are you sure you want to re-index?. This can take up to 30 minutes to complete.",
                });
            } catch {
                return;
            }

            try {
                this.taskRunning = true;
                await api.products.reindex();
            } catch {
                this.taskRunning = false;
                this.errorMessage = "Could not trigger re-index!";
                this.showErrorDialog = true;
            }
        },

        watchIndexerEvents() {
            hubs.indexer.onIndexingStarted(() => {
                console.log("Indexing started!");
                this.taskRunning = true;
            });

            hubs.indexer.onProductsIndexed((products) => {
                console.log("Products indexed!", products);
                this.taskRunning = true;
                
                const currentItems = this.indexedProducts.items;
                const newItems = products.items;
                const indexedItems = [ ...newItems, ...currentItems ].slice(0, 10);
                
                this.indexedProducts = products;
                this.indexedProducts.items = indexedItems;
            });

            hubs.indexer.onIndexingFinished(() => {
                console.log("Indexing finished!");
                this.taskRunning = false;
                this.showSuccessDialog = true;
            });

            hubs.indexer.onIndexingFailed(error => {
                console.log("Index failed!", error);
                this.taskRunning = false;

                try {
                    this.errorMessage = JSON.stringify(error);
                } catch {
                    this.errorMessage = "Unhandled error. Contact the admin to troubleshoot the problem.";
                }

                this.showErrorDialog = true;
            });

            hubs.indexer.onConnectionClosed(error => {
                console.log("Connection closed!");
                console.log("error:", error);

                this.taskRunning = false;

                if (error != undefined && error != null) {
                    try {
                        this.errorMessage = JSON.stringify(error);
                    } catch {
                        this.errorMessage = "Unhandled error. Contact the admin to troubleshoot the problem.";
                    }
    
                    this.showErrorDialog = true;
                }
            });
        },
    }, // methods

    components: {
        AdminPanelHeader,
        IndexedProductsTable,
        YesNoDialog,
    }, // components
}
</script>
