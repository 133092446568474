<template>
    <div>

        <!-- search bar -->
        <v-card class="pa-4 mb-4">
            <v-row no-gutters>
                <v-col cols="12" sm="3" class="d-flex align-center justify-center justify-sm-start">
                    <v-icon class="mr-1">bar_chart</v-icon>
                    <h5 class="ma-0">Reports</h5>
                    <v-btn class="d-sm-none" color="error" icon to="/admin-panel/reports/add">
                        <v-icon>add</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12" sm="6" class="d-flex justify-center align-center">
                    <v-text-field class="mr-4" label="Search Reports"/>
                    <v-btn color="primary">Search</v-btn>
                </v-col>
                <v-col cols="12" sm="3" class="d-flex justify-end align-center">
                    <v-btn class="d-none d-sm-flex" color="error" to="/admin-panel/reports/add">
                        Add
                        <v-icon>add</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card><!-- search bar -->
        
        <reports-list-view :isLoading="false"/>

    </div>
</template>

<script>
import ReportsListView from './ReportsListView';

export default {
    components:{
        ReportsListView,
    },
}
</script>
