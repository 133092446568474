<template>
  <div class="table-responsive">
    <v-data-table
      :headers="headers"
      :items="X12messagesPaginatedList.items"
      :server-items-length="X12messagesPaginatedList.totalCount"
      :options.sync="listViewOptions"
      :loading="isLoading"
      :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }"
    >
      
      <template v-slot:item.action="{ item }">
        <v-btn
          small
          icon
          class="primary--text"
          :to="'/admin-panel/trading-messages-view/' + item.x12messageId"
        >
          <v-icon>visibility</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
    props: ["isLoading", "X12messagesPaginatedList"],
  data() {
    return {
      headers: [
        {
          text: "Message Id",
          sortable: true,
          value: "x12messageId",
        },
        {
          text: "Correlation Id",
          sortable: true,
          value: "x12correlationId",
        },
        {
          text: "Message Process Status",
          sortable: true,
          value: "x12messageProcessStatus",
        },
        {
          text: "Received Status",
          sortable: true,
          value: "x12messageReceivedStatus",
        },
        {
          text: "Received Time",
          sortable: true,
          value: "x12messageReceivedTimestamp",
        },
        {
          text: "Reveived Validation Status",
          sortable: true,
          value: "x12messageReveivedValidationStatus",
        },
        {
          text: "Message Acknowledgment Status",
          sortable: true,
          value: "x12messageSentAcknowledgmentStatus",
        },
        {
          sortable: false,
          value: "action",
        },
      ],
      listViewOptions: {
        page: 1,
        itemsPerPage: 10,
      },
    };
  },
};
</script>

<style>
</style>