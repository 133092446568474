<template>
  <div>
    <div class="d-flex mb-6">
      <router-link class="font-weight-medium" to="/admin-panel/partners"
        >Back</router-link
      >
    </div>

    <div class="center-x" v-if="isLoadingPartner">
      <v-skeleton-loader type="text" width="100%" />
    </div>
    <v-card>
      <v-card-title>Trading partner</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Company Id"
              v-model="partner.companyId"
              required
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Partner description"
              v-model="partner.x12TradingPartnerDescription"
              required
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              label="Partner Quanlifier"
              v-model="partner.x12interchangeTradingPartnerQualifier"
              required
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Partner Identifier"
              v-model="partner.x12interchangeTradingPartnerIdentifier"
              required
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Partner Group Identifier"
              v-model="partner.x12interchangeTradingPartnerGroupIdentifier"
              required
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Partner Erp Identifier"
              v-model="partner.x12interchangeTradingPartnerErpidentifier"
              required
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Partner Type Identifier"
              v-model="partner.x12interchangeTradingPartnerTypeId"
              required
            />
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-col align-self="end" cols="auto">
            <v-btn color="primary" @click="savePartner()">Save</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    partner: {
      companyId: "",
      x12tradingPartnerDescription: "",
      x12interchangeTradingPartnerQualifier: "",
      x12interchangeTradingPartnerIdentifier: "",
      x12interchangeTradingPartnerGroupIdentifier: "",
      x12interchangeTradingPartnerErpidentifier: "",
      x12interchangeTradingPartnerTypeId: "",
    },
    isLoadingPartner: false,
    partnerEdit: {},
    isAdd: false,
    isEdit: false,
    partnerId: undefined,
  }),
  async beforeMount() {
    this.isEdit = this.$route.path.includes("edit");
    this.isAdd = this.$route.path.includes("add");

    this.partnerId = this.$route.params.id;

    if (this.partnerId) {
      this.isLoadingPartner = true;
      this.partnerEdit = await this.requestGetPartnerId();
      this.isLoadingPartner = false;

      this.partner = this.partnerEdit;
      this.partner.x12TradingPartnerDescription =this.partnerEdit.x12tradingPartnerDescription;
    }
  },

  methods: {
    ...mapActions({
      createPartner: "tradingPartner/post",
      getPartnerById: "tradingPartner/getById",
      updatePartner: "tradingPartner/update",
    }),

    savePartner() {
      if (this.isAdd) {
        this.createPartner(this.partner)
          .then(() => {
            this.$snotify.success("The partner has been added", {
              closeOnClick: false,
              pauseOnHover: false,
              timeout: 2000,
            });
            this.$router.replace("/admin-panel/partners");
          })
          .catch((error) => {
            this.$snotify.error(`Could not add partner. ${error}`, {
              closeOnClick: false,
              pauseOnHover: false,
              timeout: 4000,
            });
          });
      } else {
        this.updatePartner(this.partner)
          .then(() => {
            this.$snotify.success("The partner has been updated ", {
              closeOnClick: false,
              pauseOnHover: false,
              timeout: 2000,
            });
            this.$router.replace("/admin-panel/partners");
          })
          .catch((error) => {
            this.$snotify.error(`Could not update partner. ${error}`, {
              closeOnClick: false,
              pauseOnHover: false,
              timeout: 4000,
            });
          });
      }
    },
    async requestGetPartnerId() {
      return await this.getPartnerById(this.partnerId);
    },
  },
};
</script>