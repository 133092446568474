<template>
  <div>

    <!-- loading product -->
    <div v-if="!product" class="px-sm-4 px-md-12 py-10">
      <v-progress-linear indeterminate/>
      <p class="text-center">Loading product...</p>
    </div><!-- loading product -->

    <!-- product loaded -->
    <div v-else class="px-sm-4 px-md-12">

      <v-row v-if="!isAdd">
        <v-col cols="12" class="layout justify-start mt-0 mx-0">
          <v-icon class="mr-2" large>shopping_cart</v-icon>
          <v-text-field
            :disabled="isView"
            class="tab-input-title"
            v-model="product.stockItemName"
            required/>
        </v-col>
      </v-row>

      <!-- back button -->
      <div class="d-flex mb-6">
        <router-link class="font-weight-medium" to="/admin-panel/products">Back</router-link>
        <h4>{{getReactiveProperty}}</h4>
      </div>

      <v-card class="mb-4">
        <v-tabs :vertical="verticalTabs" :centered="!verticalTabs" :center-active="!verticalTabs">
          <v-tab class="d-flex flex-column flex-md-row justify-md-start justify-center">
            <v-icon :left="verticalTabs">info</v-icon>
            Overview
          </v-tab>
          <v-tab class="d-flex flex-column flex-md-row justify-md-start justify-center">
            <v-icon :left="verticalTabs">person</v-icon>
            Supplier
          </v-tab>
          <v-tab class="d-flex flex-column flex-md-row justify-md-start justify-center">
            <v-icon :left="verticalTabs">insert_photo</v-icon>
            Images
          </v-tab>
          <v-tab-item>
            <product-overview :product="product"></product-overview>
          </v-tab-item>
          <v-tab-item>
            <product-supplier-tab :product="product"></product-supplier-tab>
          </v-tab-item>
          <v-tab-item>
            <product-images-tab :product="product"></product-images-tab>
          </v-tab-item>
        </v-tabs>
      </v-card>

      <h6 v-if="!product.supplierId" class="text-end mb-4">Remember to add a supplier for this product!</h6>

      <div v-if="!isView">
        <h6 v-if="!product.stockItemImages || product.stockItemImages.length == 0" class="text-end mb-4">Remember to add images for this product! (optional)</h6>
      </div>

      <div class="d-flex justify-end" v-if="!isView">
        <v-btn
          :loading="isPerformingOperation"
          :disabled="isPerformingOperation || !isProductValid"
          color="error"
          large
          @click="onClickSave">
          Save
        </v-btn>
      </div>
    </div><!-- product loaded -->

  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, mapMutations } from "vuex";
import ProductOverview from "./ProductOverview";
import ProductSupplierTab from './ProductSupplierTab.vue';
import ProductImagesTab from './ProductImagesTab.vue';
import apiClient from '../../../apiClient';

export default {

  data: () => ({
    isView: false,
    isAdd: false,
    isEdit:false,
    isPerformingOperation: false,
    id: undefined,
    verticalTabs: true,
    product: {},
  }), // data

  mounted() {
    this.id = this.$route.params.id;
    this.isView = this.$route.path.includes("view");
    this.isAdd = this.$route.path.includes("add");
    this.isEdit = this.$route.path.includes("edit");

    if (this.id) {
      this.setProduct(undefined); // for loading animations
      this.setCurrentProductId(this.id);
      //this.getProductByIdWithTags();

      (async () => {
        this.product = await apiClient.products.getByStockItemIdWithTags(this.id);
        console.log("PRODUCT IS", this.product);
      })()
    }

    if (this.isAdd) {
      this.setCurrentProductId(-1);
      this.setProduct({});
    } 


    // watch for window dimensions (for tabs responsiveness)
    this.$nextTick(() => window.addEventListener('resize', () => this.verticalTabs = window.innerWidth >= 960));
    this.verticalTabs = window.innerWidth >= 960;
  }, // mounted

  methods: {
    ...mapActions({
      getProductByIdWithTags: "products/getByIdWithTags",
      createProduct: "products/createProduct",
      updateProduct: "products/updateProduct",
    }),

    ...mapMutations({
      setCurrentProductId: "products/SET_CURRENT_PRODUCT_ID",
      setSelectedImage: "products/SET_SELECTED_IMAGE",
      setProduct: 'products/SET_PRODUCT',
    }),
    
    onClickSave() {
      // TODO Remove whese once there is actual data in the database
      this.product.supplierId = 1;
      this.product.colorId = 1;
      this.product.unitPackageId = 1;
      this.product.outerPackageId = 1;
      this.product.lastEditedBy = 1;
      
      // Create product
      if (this.isAdd) {
        this.isPerformingOperation = true;

        this.createProduct({
          product: this.product,
          onSuccessCallback: (result) => {
            this.$snotify.success("The product has been added", {
              closeOnClick: false,
              pauseOnHover: false,
              timeout: 2000
            });

            this.isPerformingOperation = false;

            // TODO Redirect to product edit once I get the id of the created product
            this.$router.replace('/admin-panel/products'); // TODO Remove this later and uncomment next line
            // this.$router.replace(`/admin-panel/product-edit/`);
          },
          onErrorCallback: (error) => {
            this.$snotify.error("The product could not be added", {
              closeOnClick: false,
              pauseOnHover: false,
              timeout: 2000
            });

            this.isPerformingOperation = false;
          }
        });
      }
      
      // Edit product
      else if (this.isEdit) {
        console.log('Entered in save update');
        this.isPerformingOperation = true;

        this.updateProduct(this.product)
        .then(result => {
          this.$snotify.success("The product has been updated", {
            closeOnClick: false,
            pauseOnHover: false,
            timeout: 2000
          });
        })
        .catch(error => {
          this.$snotify.error("The product could not be updated", {
            closeOnClick: false,
            pauseOnHover: false,
            timeout: 2000
          });
        })
        .finally(() => this.isPerformingOperation = false);
      }
    },
  }, // methods

  computed: {
    ...mapGetters({
      //product: "products/getProduct",
      selectedImage: "products/getSelectedProductImage",
      iriSegments: "products/getIRISegments",
      iriTypes: "products/getIRITypes",
      categories: "products/getCategories"
    }),
    getReactiveProperty(){
      
      return `this is brand type ${this.product.subType} this is category ${this.product.type}`

    },

    isProductValid() {
      if (!this.product.stockItemName || this.product.stockItemName.trim().length == 0)
        return false;

      // if (!this.product.brand || this.product.brand.trim().length == 0)
      //   return false;

      if (!this.product.type || this.product.type.trim().length == 0)
        return false;

      if (!this.product.supplierId)
        return false;

      return true;
    },
  }, //  computed

  components: {
    ProductOverview,
    ProductSupplierTab,
    ProductImagesTab,
  }, // components
};
</script>
