<template>
  <div>
    <!-- static main header -->
    <div class="headerV2-wrap">
      <!-- expired license warning -->
      <div
        v-if="isAnyLicenseExpired"
        class="d-flex justify-center align-center pa-1"
        style="background-color: #f44336"
      >
        <v-icon dark class="mr-1">warning</v-icon>

        <h6 class="font-weight-regular ma-0 pa-0">
          <span class="mr-2" style="color: white"
            >One of your licenses is expired or your account is on hold!</span
          >
        </h6>

        <h6 class="font-weight-regular ma-0 pa-0">
          <router-link to="/account/profile?tab=licenses"
            >View my licenses</router-link
          >
        </h6>
      </div>
      <!-- expired license warning -->

      <div class="header-wrap">
        <!-- top (logo, search, location, cart and user profile) -->
        <div class="top-header-wrap d-block primary">
          <div class="top-header" :style="{ 'background-color': primaryColor }">
            <v-container grid-list-xl>
              <v-layout row wrap align-center justify-space-between pa-0 ma-0>
                <!-- logo -->
                <v-flex xs6 sm6 md4 lg3 xl3 px-0>
                  <div class="site-logo d-inline-block">
                    <router-link to="/">
                      <img
                        alt="site-logo"
                        height="85"
                        :src="appLogo"
                        width="240"
                      />
                    </router-link>
                  </div> </v-flex
                ><!-- logo -->

                <!-- search bar -->
                <v-flex
                  v-if="autoCompleteData && !isAnyLicenseExpired"
                  xs12
                  sm7
                  md5
                  lg7
                  xl7
                  search-v2-wrap
                >
                  <div
                    class="search-v2 placeholder"
                    :data-placeholder="
                      autoCompleteData &&
                      autoCompleteData.length > 0 &&
                      showOptions &&
                      autoCompleteData[0].name.includes(searchValue)
                        ? autoCompleteData[0].name
                        : ''
                    "
                  >
                    <input
                      v-model="searchValue"
                      @keyup.enter="search"
                      @keyup="onKeyAutoComplete"
                      @focus="showOptions = true"
                      @blur="hideOptions()"
                      type="text"
                      placeholder="Enter a keyword to search"
                    />
                    <div
                      class="input-select"
                      v-if="
                        autoCompleteData &&
                        autoCompleteData.length > 0 &&
                        showOptions
                      "
                    >
                      <div
                        v-for="data of autoCompleteData.filter(
                          (x) => x.name != autoCompleteData[0].name
                        )"
                        :key="data.name + Math.random(1 * 100) * 50"
                        @click="selectSearchOption(data)"
                        v-html="data.name"
                      ></div>
                    </div>
                  </div> </v-flex
                ><!-- search bar -->

                <!-- location, cart and user profile -->
                <v-flex xs6 sm6 md3 lg2 xl2 px-0>
                  <div
                    class="
                      notifications
                      d-flex
                      align-items-center
                      justify-end
                      mb-2 mb-sm-0
                    "
                  >
                    <!-- location desktop -->
                    <v-toolbar-title
                      class="d-none d-sm-block mr-1"
                      @click="isSwitchingLocation = true"
                    >
                      <a
                        href="javascript:void(0)"
                        class="white--text font-weight-regular"
                      >
                        {{ currentLocation.locationName }}
                      </a>
                      <v-icon dark>arrow_drop_down</v-icon> </v-toolbar-title
                    ><!-- location desktop -->

                    <emb-cart v-if="!isAnyLicenseExpired" />

                    <emb-user-block
                      :userBlockItems="userBlockItems"
                    ></emb-user-block>
                  </div>

                  <!-- location mobile -->
                  <div class="d-flex justify-end">
                    <v-toolbar-title
                      class="d-block d-sm-none mr-1"
                      @click="isSwitchingLocation = true"
                    >
                      <a
                        href="javascript:void(0)"
                        class="white--text font-weight-regular"
                        >{{ currentLocation.locationName }}</a
                      >
                      <v-icon dark>arrow_drop_down</v-icon>
                    </v-toolbar-title>
                  </div>
                  <!-- location mobile --> </v-flex
                ><!-- location, cart and user profile -->
              </v-layout>
            </v-container>
          </div>
        </div>
        <!-- top (logo, search, location, cart and user profile) -->

        <!-- bottom (menu items and search button) -->
        <div
          class="bottom-header"
          :style="{ 'background-color': primaryColor }"
        >
          <v-container>
            <div class="bottom-header-inner">
              <!-- menu items -->
              <div class="menu-alignment">
                <emb-menu :menus="getMenuItems()"></emb-menu>
              </div>
              <!-- menu items -->

              <!-- menu items (mobile) -->
              <div class="layout align-left responsive-menu">
                <v-btn
                  icon
                  dark
                  @click="toggleMobileSidebar"
                  class="toggle-btn"
                >
                  <i class="material-icons">menu</i>
                </v-btn>
              </div>
              <!-- menu items (mobile) -->

              <!-- search button -->
              <!-- TODO here -->
              <emb-search
                v-if="!isAnyLicenseExpired"
                @search="onMobileSearchTriggered"
              >
              </emb-search>
            </div>
          </v-container>
        </div>
        <!-- bottom (menu items and search button) -->
      </div>
    </div>
    <!-- static main header -->

    <div id="fixedHeader">
      <emb-fixed-header :menus="getMenuItems()"></emb-fixed-header>
    </div>

    <!-- dialogs -->
    <div>
      <!-- logout dialog -->
      <v-dialog persistent max-width="300" v-model="isLoggingOut">
        <v-card class="pa-3">
          <v-card-text class="d-flex flex-column align-center">
            <p>Logging out</p>
            <v-progress-circular width="2" indeterminate />
          </v-card-text>
        </v-card> </v-dialog
      ><!-- logout dialog -->

      <!-- switch location dialog -->
      <v-dialog max-width="500" v-model="isSwitchingLocation">
        <v-card>
          <v-card-text>
            <!-- current location -->
            <div v-if="currentLocation">
              <h5 class="font-weight-regular pt-6 mb-3">
                Your current location is
              </h5>

              <v-card class="mb-6">
                <v-card-title>{{ currentLocation.locationName }}</v-card-title>
              </v-card>
            </div>
            <!-- current location -->

            <h5 class="font-weight-regular mb-3">Select another location</h5>

            <!-- other locations -->
            <div v-for="location in locations" :key="location.locationId">
              <v-hover v-slot:default="{ hover }">
                <v-card
                  :elevation="hover ? 6 : 2"
                  :class="{ 'on-hover': hover }"
                  class="hoverable mb-3"
                  @click="onLocationSelected(location)"
                >
                  <v-card-title>{{ location.locationName }}</v-card-title>
                </v-card>
              </v-hover>
            </div>
            <!-- other locations -->
          </v-card-text>
        </v-card> </v-dialog
      ><!-- switch location dialog -->

      <!-- switching location loading dialog -->
      <v-dialog persistent max-width="300" v-model="isLoadingLocation">
        <v-card class="pa-3">
          <v-card-text class="d-flex flex-column align-center">
            <p>Switching location...</p>
            <v-progress-circular width="2" indeterminate />
          </v-card-text>
        </v-card> </v-dialog
      ><!-- switching location loading dialog -->
    </div>
    <!-- dialogs -->
  </div>
</template>

<script>
/* eslint-disable */

import Menu from "./Menu";
import Cart from "./Cart";
import Wishlist from "./Wishlist";
import UserBlock from "./UserBlock";
import Search from "./Search";
import { mapMutations, mapActions, mapGetters } from "vuex";
import { search } from "../../../mixins/index";

export default {
  data() {
    return {
      searchValue: null,
      appLogo: process.env.VUE_APP_LOGO,
      primaryColor: process.env.VUE_APP_COLOR_PRIMARY,
      showOptions: false,
      isLoggingOut: false,
      isSwitchingLocation: false,
      isLoadingLocation: false,
      userBlockItems: [
        {
          icon: "account_circle",
          title: "Profile",
          onClick: () => this.$router.push("/me/profile"),
        },
        {
          icon: "compare_arrows",
          title: "Switch Account",
          onClick: () => this.$router.push("/switch/account"),
        },
        {
          icon: "local_post_office",
          title: "Messages",
          onClick: () => this.$router.push("/account/profile"),
        },
        {
          icon: "power_settings_new",
          title: "LogOut",
          onClick: () => {
            this.isLoggingOut = true;
            this.$msal.logout();
          },
        },
      ],
    };
  }, // data

  components: {
    embMenu: Menu,
    embCart: Cart,
    embWishlist: Wishlist,
    embUserBlock: UserBlock,
    embSearch: Search,
  }, // components

  mounted() {
    this.getProductCategories();
    this.fixedHeader();
    this.getCurrentOrder();
    this.getLocations();
    

    const searchValue = this.$route.query.search;

    if (searchValue && searchValue.trim().length > 0) {
      console.log("This is searchWithValue =================", searchValue)
      this.searchWithValue(searchValue);
    }
  }, // mounted

  methods: {
    ...mapMutations({
      setSearchValue: "search/SET_SEARCH_VALUE",
      setAutoCompleteData: "search/SET_AUTOCOMPLETE_DATA",
      setFacets: "search/SET_FACETS",
      setFacetsFormat:"search/SET_FACETS_FORMAT",
      setPageNumber: "search/SET_PAGE_NUMBER",
    }),

    ...mapActions({
      getProducts: "search/get",
      getAutocomplete: "search/getAutocomplete",
      getCurrentOrder: "order/getCurrentOrder",
      getLocations: "catalog/requestGetLocations",
      switchLocation: "session/switchLocation",
      getProductCategories: "search/getProductCategoriesRequest",
    }),

    getMenuItems() {
      return search.methods.createMenuItems(
        this.productCategories,
        this.isAccountSelected,
        this.isAnyLicenseExpired
      );
    },

    toggleMobileSidebar() {
      this.$store.dispatch("toggleSidebar", true);
    },

    onKeyAutoComplete() {
      this.setSearchValue(this.searchValue);
      if (this.searchValue && this.searchValue.length > 0)
        this.getAutocomplete();
      else this.setAutoCompleteData({ data: [] });
    },

    search() {
      console.log("search!", this.searchValue);
      this.$router.push(`/search-results/${this.searchValue}`);
    },

    searchWithValue(value) {
      console.log("%c searchWithValue", "background: black; color: yellow");
      this.setSearchValue(value);

      this.setFacets(
        this.request.facets.map((x) => {
          x.options = x.options.map((o) => {
            o.value = false;
            return o;
          });
          return x;
        })
      );

      if (this.$router.currentRoute.path != "/products") {
        this.$router.push("/products");
      } else {
        this.getProducts();
      }
    },

    selectSearchOption(data) {
      console.log("Select search option", data);
      this.setSearchValue("");
      this.searchValue = "";
      if (!data.id) return;
      this.$router.push("/products/" + data.type + "/" + data.id);
    },
    fixedHeader() {
      var fixedHeader = document.getElementById("fixedHeader");
      window.onscroll = function () {
        if (window.pageYOffset > 100) {
          fixedHeader.style.opacity = "1";
          fixedHeader.style.top = "0";
          fixedHeader.style.visibility = "visible";
        } else {
          fixedHeader.style.opacity = "0";
          fixedHeader.style.top = "-100px";
          fixedHeader.style.visibility = "hidden";
        }
      };
    },
    hideOptions() {
      var that = this;
      setTimeout(() => {
        that.showOptions = false;
      }, 500);
    },

    onLocationSelected(location) {
      this.isSwitchingLocation = false;
      this.isLoadingLocation = true;

      this.switchLocation(location.locationId).then(() => {
        this.getProductCategories();
        this.$router.go();
      });
    },

    onMobileSearchTriggered(searchValue) { // TODO here
      console.log("searchValue", searchValue);
      this.$router.push(`/search-results/${searchValue}`);
    },
  }, // methods

  computed: {
    ...mapGetters(["menus", "clientPanelMenusNoAccount"]),
    ...mapGetters({
      autoCompleteData: "search/getAutoCompleteData",
      locations: "catalog/getLocations",
      currentPerson: "session/getPerson",
      currentLocation: "session/getLocation",
      request: "search/request",
      isAccountSelected: "session/isAccountSelected",
      productCategories: "search/getProductCategories",
      isAnyLicenseExpired: "session/isAnyLicenseExpired",
      
    }),
  }
};
</script>

<style lang="scss" scoped>
.placeholder {
  position: relative;
}

.placeholder::after {
  position: absolute;
  left: 40px;
  top: 7px;
  content: attr(data-placeholder);
  pointer-events: none;
  opacity: 0.6;
  color: white;
}
.input-select {
  z-index: 999;
  background: white;
  position: absolute;
  width: 93%;
}

.input-select div {
  padding: 10px;
}

.input-select div:hover {
  background-color: #f3f3f4;
}

.hoverable:hover {
  cursor: pointer;
}
</style>
