var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-responsive" },
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.paginatedList.items,
          "server-items-length": _vm.paginatedList.totalCount,
          options: _vm.listViewOptions,
          loading: _vm.isLoading,
          "footer-props": { "items-per-page-options": [10, 20, 30, 40, 50] }
        },
        on: {
          "update:options": function($event) {
            _vm.listViewOptions = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item.orderDate",
            fn: function(ref) {
              var item = ref.item
              return [_vm._v(" " + _vm._s(_vm.getOrderDate(item)) + " ")]
            }
          },
          {
            key: "item.expectedDeliveryDate",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " + _vm._s(_vm.getOrderExpectedDeliveryDate(item)) + " "
                )
              ]
            }
          },
          {
            key: "item.lastEditedDate",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(" " + _vm._s(_vm.getOrderLastEditedDate(item)) + " ")
              ]
            }
          },
          {
            key: "item.lastEditedBy",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(" " + _vm._s(_vm.getLastEditedByPerson(item)) + " ")
              ]
            }
          },
          {
            key: "item.status",
            fn: function(ref) {
              var item = ref.item
              return [
                item.isOrderSubmitted
                  ? _c("span", { staticStyle: { color: "green" } }, [
                      _vm._v("Submitted")
                    ])
                  : _c("span", { staticStyle: { color: "gray" } }, [
                      _vm._v("Pending")
                    ])
              ]
            }
          },
          {
            key: "item.action",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-btn",
                  {
                    staticClass: "primary--text",
                    attrs: {
                      small: "",
                      icon: "",
                      to: "/admin-panel/order-view/" + item.orderId
                    }
                  },
                  [_c("v-icon", [_vm._v("visibility")])],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }