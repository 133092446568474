<template>
  <div>
    <v-card class="pa-4 mb-4">
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="3"
          class="d-flex align-center justify-center justify-sm-start"
        >
          <v-icon class="mr-2">mdi-handshake</v-icon>
          <h5 class="ma-0">Trading Messages</h5>
        </v-col>
        <v-col cols="12" sm="6" class="d-flex justify-center align-center">
          <v-text-field
            class="mr-4"
            label="Search partners"
            v-model="searchValue"
            @change="executeSearchRequestRequest"
          />
          <v-btn @click="executeSearchRequestRequest" color="primary"
            >Search</v-btn
          >
        </v-col>
        
      </v-row>
    </v-card>
    <messages-list-view
      :isLoading="isLoading"
      :X12messagesPaginatedList="X12messagesPaginatedList"
    ></messages-list-view>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import MessagesListView from "./TradingMessagesListView.vue";
export default {
  data() {
    return {
      searchValue: undefined,
      isLoading: false,
      X12messagesPaginatedList: {},
    };
  },
  mounted() {
    this.getTradingMessagesRequest();
  },
  components: {
    MessagesListView,
  },
  methods: {
    ...mapActions({
      getMessages: "tradingPartner/getMessages",
    }),
    async getTradingMessagesRequest() {
      this.isLoading = true;
      try{
        this.X12messagesPaginatedList = await this.getMessages();
        
      }catch(error){
        this.$snotify.error(`Could delete partner. ${error}`, {
          closeOnClick: false,
          pauseOnHover: false,
          timeout: 4000,
        });
        this.isLoading = false;

      }
      this.isLoading = false;
    },
    async executeSearchRequestRequest() {
      this.isLoading = true;
      try {
        this.X12messagesPaginatedList = await this.getMessages({
          searchValue: this.searchValue,
        });
        this.isLoading = false;
      } catch (error) {
        console.log(error,'entro enel catch')
        this.$snotify.error(`Could delete partner. ${error}`, {
          closeOnClick: false,
          pauseOnHover: false,
          timeout: 4000,
        });
        
      }
      this.isLoading = false;
    },
  },
};
</script>

<style>
</style>