var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex mb-6" },
        [
          _c(
            "router-link",
            {
              staticClass: "font-weight-medium",
              attrs: { to: "/admin-panel/trading-messages" }
            },
            [_vm._v("Back")]
          )
        ],
        1
      ),
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "center-x" },
            [
              _c("v-skeleton-loader", {
                attrs: { type: "text", width: "100%" }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Trading Message")]),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: { disabled: "", label: "Correlation Id" },
                        model: {
                          value: _vm.tradingMessage.x12correlationId,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.tradingMessage,
                              "x12correlationId",
                              $$v
                            )
                          },
                          expression: "tradingMessage.x12correlationId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Message Process Status",
                          disabled: ""
                        },
                        model: {
                          value: _vm.tradingMessage.x12messageProcessStatus,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.tradingMessage,
                              "x12messageProcessStatus",
                              $$v
                            )
                          },
                          expression: "tradingMessage.x12messageProcessStatus"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Received Status", disabled: "" },
                        model: {
                          value: _vm.tradingMessage.x12messageReceivedStatus,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.tradingMessage,
                              "x12messageReceivedStatus",
                              $$v
                            )
                          },
                          expression: "tradingMessage.x12messageReceivedStatus"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Received Time", disabled: "" },
                        model: {
                          value: _vm.tradingMessage.x12messageReceivedTimestamp,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.tradingMessage,
                              "x12messageReceivedTimestamp",
                              $$v
                            )
                          },
                          expression:
                            "tradingMessage.x12messageReceivedTimestamp"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Reveived Validation Status",
                          disabled: ""
                        },
                        model: {
                          value:
                            _vm.tradingMessage
                              .x12messageReveivedValidationStatus,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.tradingMessage,
                              "x12messageReveivedValidationStatus",
                              $$v
                            )
                          },
                          expression:
                            "tradingMessage.x12messageReveivedValidationStatus"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Message Acknowledgment Status",
                          disabled: ""
                        },
                        model: {
                          value:
                            _vm.tradingMessage
                              .x12messageSentAcknowledgmentStatus,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.tradingMessage,
                              "x12messageSentAcknowledgmentStatus",
                              $$v
                            )
                          },
                          expression:
                            "tradingMessage.x12messageSentAcknowledgmentStatus"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }