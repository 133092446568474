//-----------------------| Search Module |-------------------//

/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import searchClient from "@/services/azsearch.service";
import api from "@/api";
import Vue from 'vue'

const state = {
  searchString: '*',
  moduleName: 'product',
  searchResults: [],
  searchResultsCount: 0,
  facets: [],
  categories: [
    'Wine',
    'Beer',
    'Spirits',
    'Cider',
    'Sake',
    'Mead',
    'Combo',
    'Glassware',
    'Mixers',
    'Non-alcoholic',
    'Seltzer',
  ],

  productCategories: [
    {
      categoryName: 'Wine',
      styles: [
        'Red',
        'White',
        'Rosé',
        'Sherry',
        'Port',
        'Sangría',
        'Fruit',
        'Vermouth',
        'Speciality',
        'Vin de Liqueur'
      ],
    },
    {
      categoryName: 'Beer',
      subTypes: [
        'Ale',
        'Sour',
        'Stout/Porter',
        'Belgian Style Ale',
        'Lager',
        'American Lager',
        'Wheat',
        'Speciality',
        'FMB',
      ],
    },
    {
      categoryName: 'Spirits',
      subTypes: [
        'Whiskey',
        'Liqueur',
        'Brandy',
        'Rum',
        'Mezcal',
        'Vodka',
        'Cocktail Bitters',
        'Tequila',
        'Gin',
        'Ready-to-Drink',
      ],
    },
    {
      categoryName: 'Cider',
    },
    {
      categoryName: 'Sake',
    },
    {
      categoryName: 'Mead',
    },
    {
      categoryName: 'Combo',
    },
    {
      categoryName: 'Mead',
    },
    {
      categoryName: 'Glassware',
    },
    {
      categoryName: 'Mixers',
    },
    {
      categoryName: 'Non-alcoholic',
    },
    {
      categoryName: 'Seltzer',
    },
  ], // productCategories

  colors: [
    'Gray',
    'Orange',
    'Red',
    'Rosé',
    'Tawny',
    'White',
    'Yellow',
  ],
  products: {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    totalPages: 0,
    totalCount: 0
  },
  product: { },
  iriTypes: [
    "Hard Soda",
    "Hard Water",
    "Non Alcohol",
    "Cider",
    "PAB",
    "High Alcohol",
    "Hard Tea",
    "Other",
    "Light",
    "Assorted Types",
    "FMB",
    "Regular"
  ],
  iriSegments: [
    "Non Alcohol",
    "Value",
    "Premium",
    "Near Beer",
    "Assorted Segment",
    "Premium Plus",
    "Craft/Import",
    "Other"
  ],
  sizes: [
    'Extra Small',
    'Small',
    'Medium',
    'Large',
    'Extra Large',
  ],
  packageTypes: [
    'Bag',
    'Box',
    'Other',
  ],
  selectedImage: undefined,
  currentProductId: 0,
  productRequest: {
    pageSize: 10,
    pageNumber: 1,
    searchValue: undefined, // it can be either stockItemName or internalId
    sortField: 'stockItemName',
    sortDirection: 'ASC',
  }
}

const getters = {
  searchString: state => {
    return state.searchString;
  },
  searchResults: state => {
    return state.searchResults;
  },
  searchResultsCount: state => {
    return state.searchResultsCount;
  },
  facets: state => {
    return state.facets;
  },
  getProducts: state => {
    return state.products;
  },
  request: state => {
    return state.productRequest
  },
  getProduct: state => state.product,
  getSelectedProductImage: state => state.selectedImage,
  getIRITypes: state => state.iriTypes,
  getIRISegments: state => state.iriSegments,
  getCategories: state => state.categories,
  getColors: state => state.colors,
  getSizes: state => state.sizes,
  getPackageTypes: state => state.packageTypes,
  getProductCategories: state => state.productCategories,
}

const actions = {
  get({ state, commit }) {
    api.get(`${state.moduleName}`, { params: state.productRequest })
      .then(result => {
        commit("SET_PRODUCTS", result)
      })
      .catch(error => console.error(error));
  },

  getById({ state, commit }) {
    api.get(`${state.moduleName}/${state.currentProductId}`)
      .then(result => {
        console.log("resulttttttt:", result.data);
        commit("SET_PRODUCT_FROM_REQUEST", result)
      });
  },

  getByIdWithTags({ state, commit }) {
    api.get(`${state.moduleName}/${state.currentProductId}/tags`)
      .then(result => {
        console.log("result with tags:", result.data);
        commit("SET_PRODUCT_FROM_REQUEST", result)
      });
  },
    
  getByPromotedTags() {
    return api.get(`${state.moduleName}/tag/promoted`);
  },

  update({ state, commit }) {
    api.put(`${state.moduleName}/${state.currentProductId}`, state.product).then(result => commit("SET_PRODUCT_FROM_REQUEST", result));
  },
  updateProduct({ state, commit }, product) {
    return api.put(`${state.moduleName}/${product.stockItemId}`, product)
  },
  create({ state, commit }) {
    api.post(`${state.moduleName}`, state.product).then(result => result);
  },
  createProduct({ state, commit }, { product, onSuccessCallback, onErrorCallback }) {
    api.post(`${state.moduleName}`, product)
      .then(result => onSuccessCallback(result.data))
      .catch(error => {
        console.error(error)
        onErrorCallback(error);
      });
  },
  executeSearch({ state, commit }) {
    console.log('executeSearch()');
    searchClient.search(
      "global-catalog",
      {
        search: "bud",
        count: true
      },
      (err, searchResults, raw) => {
        commit("SET_RESULTS", searchResults);
        commit("SET_RESULTS_COUNT", raw["@odata.count"]);
        commit("SET_FACETS", raw["@search.facets"]);
      }
    );
  },

  setSearchString({ dispatch, commit }, value = "*") {
    console.log('searching')
    commit("SET_SEARCHSTRING", value);
    dispatch("executeSearch");
  },

  requestGetProductsByNameMatch({ state, commit }, stockItemName) {
    api.get(`${state.moduleName}/search/${stockItemName}`)
      .then(result => commit('SET_PRODUCTS', result))
      .catch(error => console.error(error));
  },

  assignTag({ state, commit }, { stockItemId, stockItemTagId } ) {
    const query = `stockItemId=${stockItemId}&stockItemTagId=${stockItemTagId}`;
    return api.put(`${state.moduleName}/tag/assign?${query}`)
    //.then(result => commit("SET_PRODUCT", result.data));
  },

  unassignTag({ state, commit }, { stockItemId, stockItemTagId } ) {
    const query = `stockItemId=${stockItemId}&stockItemTagId=${stockItemTagId}`;
    return api.put(`${state.moduleName}/tag/unassign?${query}`)
    //.then(result => commit("SET_PRODUCT", result.data));
  },
} // actions

const mutations = {
  SET_RESULTS(state, data) {
    state.searchResults = data;
  },
  SET_PAGE_NUMBER(state, data) {
    state.productRequest.pageNumber = data;
  },
  SET_PAGE_SIZE(state, data) {
    state.productRequest.pageSize = data;
  },
  SET_SEARCH_PARAM_SEARCH_VALUE(state, searchValue) {
    state.productRequest.searchValue = searchValue;
  },
  RESET_SEARCH_PARAMS(state) {
    state.productRequest.pageSize = 10;
    state.productRequest.pageNumber = 1;
    state.productRequest.searchValue = undefined;
    state.productRequest.sortField = 'stockItemName';
    state.productRequest.sortDirection = 'ASC';
  },
  SET_PRODUCT_FROM_REQUEST(state, data) {

    state.product = data.data;
    state.selectedImage = data.data?.stockItemImages[0]?.location; // add logic later when there are image types.
  },
  SET_PRODUCT(state, data) {
    console.log("SET_PRODUCT", data);
    state.product = data;
  },
  SET_SELECTED_IMAGE(state, data) {
    state.selectedImage = data;
  },
  SET_PRODUCTS(state, data) {
    state.products = data.data;

    Vue.set(state.products, 'items', data.data.items.map(prod => {
      prod.name = prod.productShortDescription || prod.productDescription;
      return prod;
    }));
  },
  SET_CURRENT_PRODUCT_ID(state, data) {
    state.currentProductId = data;
  },
  SET_FACETS(state, data) {
    state.facets = data;
  },
  SET_RESULTS_COUNT(state, count) {
    state.searchResultsCount = count;
  },
  SET_SEARCHSTRING(state, value) {
    state.searchString = value;
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}