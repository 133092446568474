var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c("div", { staticClass: "d-flex" }, [
            _c(
              "p",
              {
                staticClass: "cursor-pointer font-weight-bold",
                on: {
                  click: function($event) {
                    return _vm.$router.go(-1)
                  }
                }
              },
              [_vm._v("Back")]
            )
          ]),
          _c(
            "v-card",
            [
              _c("div", { staticClass: "bg-grey pa-4" }, [
                _c(
                  "div",
                  [
                    _vm.isLoadingOrder
                      ? _c(
                          "div",
                          { staticClass: "mb-8" },
                          [
                            _c("v-skeleton-loader", { attrs: { type: "text" } })
                          ],
                          1
                        )
                      : !_vm.isLoadingOrder && _vm.order.isOrderFinalized
                      ? _c(
                          "v-alert",
                          {
                            staticClass: "mb-4",
                            attrs: { dense: "", type: "success" }
                          },
                          [
                            _vm._v("This order has been "),
                            _c("strong", [_vm._v("finalized")])
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c("div", { staticClass: "mb-4" }, [
                  _vm.isLoadingOrder
                    ? _c(
                        "div",
                        { staticClass: "center-x" },
                        [
                          _c("v-skeleton-loader", {
                            attrs: { type: "text", width: "200px" }
                          })
                        ],
                        1
                      )
                    : _c("h5", { staticClass: "text-center" }, [
                        _vm._v("Transaction ID: " + _vm._s(_vm.order.orderId))
                      ])
                ]),
                _c("div", [
                  _vm.isLoadingOrder
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "center-x mb-3" },
                          [
                            _c("v-progress-circular", {
                              staticStyle: { color: "darkgrey" },
                              attrs: { indeterminate: "", size: "48" }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "center-x" },
                          [
                            _c("v-skeleton-loader", {
                              attrs: { type: "text", width: "100px" }
                            })
                          ],
                          1
                        )
                      ])
                    : _c("div", [
                        _vm.order.isOrderSubmitted
                          ? _c("div", [
                              _c(
                                "div",
                                { staticClass: "d-flex justify-center" },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { size: "64", color: "green" } },
                                    [_vm._v("check_circle")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "h5",
                                {
                                  staticClass: "text-center ma-0",
                                  staticStyle: { color: "green" }
                                },
                                [_vm._v("SUBMITTED")]
                              )
                            ])
                          : _c("div", [
                              _c(
                                "div",
                                { staticClass: "d-flex justify-center" },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { size: "64", color: "gray" } },
                                    [_vm._v("report_problem")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "h5",
                                {
                                  staticClass: "text-center ma-0",
                                  staticStyle: { color: "gray" }
                                },
                                [_vm._v("PENDING")]
                              )
                            ])
                      ])
                ])
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
                        _vm.isLoadingOrder
                          ? _c(
                              "div",
                              [
                                _c("h6", { staticClass: "mb-2" }, [
                                  _vm._v("Summary")
                                ]),
                                _c("v-skeleton-loader", {
                                  staticClass: "mb-1",
                                  attrs: { type: "text", width: "150px" }
                                }),
                                _c("v-skeleton-loader", {
                                  staticClass: "mb-1",
                                  attrs: { type: "text", width: "150px" }
                                }),
                                _c("v-skeleton-loader", {
                                  staticClass: "mb-1",
                                  attrs: { type: "text", width: "150px" }
                                })
                              ],
                              1
                            )
                          : _c("div", [
                              _c("h6", [_vm._v("Summary")]),
                              _c("p", { staticClass: "mb-1" }, [
                                _vm._v("Order ID: "),
                                _c("b", [_vm._v(_vm._s(_vm.order.orderId))])
                              ]),
                              _c("p", { staticClass: "mb-1" }, [
                                _vm._v("Order Date: "),
                                _c("b", [_vm._v(_vm._s(_vm.getOrderDate()))])
                              ]),
                              _c("p", { staticClass: "mb-1" }, [
                                _vm._v("Order Total: "),
                                _c(
                                  "b",
                                  [
                                    _c("emb-currency-sign"),
                                    _vm._v(" " + _vm._s(_vm.getOrderTotal()))
                                  ],
                                  1
                                )
                              ])
                            ])
                      ]),
                      _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
                        _vm.isLoadingOrder
                          ? _c(
                              "div",
                              [
                                _c("h6", { staticClass: "mb-2" }, [
                                  _vm._v("Deliver To")
                                ]),
                                _c("v-skeleton-loader", {
                                  staticClass: "mb-1",
                                  attrs: { type: "text", width: "150px" }
                                }),
                                _c("v-skeleton-loader", {
                                  staticClass: "mb-1",
                                  attrs: { type: "text", width: "150px" }
                                }),
                                _c("v-skeleton-loader", {
                                  staticClass: "mb-1",
                                  attrs: { type: "text", width: "150px" }
                                })
                              ],
                              1
                            )
                          : _c("div", [
                              _c("h6", [_vm._v("Deliver To")]),
                              _c("p", { staticClass: "mb-1" }, [
                                _vm._v(_vm._s(_vm.order.customer.customerName))
                              ]),
                              _c("p", { staticClass: "mb-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.order.customer.deliveryAddressLine1
                                  ) +
                                    ", " +
                                    _vm._s(
                                      _vm.order.customer.deliveryCity.cityName
                                    )
                                )
                              ]),
                              _c("p", { staticClass: "mb-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.order.customer.deliveryAddressLine2
                                  )
                                )
                              ]),
                              _c("p", { staticClass: "mb-1" }, [
                                _vm._v(
                                  "Contact No. " +
                                    _vm._s(_vm.order.customer.phoneNumber)
                                )
                              ])
                            ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "bg-grey pa-4" }, [
                _vm.isLoadingOrder
                  ? _c("div", [
                      _c(
                        "h4",
                        {
                          staticClass:
                            "text-center ma-0 mb-4 font-weight-regular"
                        },
                        [_vm._v("Expected Date of Delivery")]
                      ),
                      _c(
                        "div",
                        { staticClass: "center-x" },
                        [
                          _c("v-skeleton-loader", {
                            attrs: { type: "text", width: "180px" }
                          })
                        ],
                        1
                      )
                    ])
                  : _c("div", [
                      _c(
                        "h4",
                        { staticClass: "text-center ma-0 font-weight-regular" },
                        [_vm._v("Expected Date of Delivery")]
                      ),
                      _c("h3", { staticClass: "text-center ma-0" }, [
                        _vm._v(_vm._s(_vm.getOrderExpectedDeliveryDate()))
                      ])
                    ])
              ]),
              _c("div", [
                _c("h4", { staticClass: "ma-4" }, [
                  _vm._v("You order details")
                ]),
                _vm.isLoadingOrder
                  ? _c(
                      "div",
                      { staticClass: "center-x my-8" },
                      [
                        _c("v-progress-circular", {
                          staticStyle: { color: "darkgrey" },
                          attrs: { indeterminate: "", size: "80" }
                        })
                      ],
                      1
                    )
                  : _c(
                      "div",
                      _vm._l(_vm.order.orderLines, function(orderLine, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _c("OrderLineItem", {
                              attrs: {
                                item: orderLine,
                                canEditQuantity: _vm.isOrderEditable,
                                canRemove: _vm.isOrderEditable
                              }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    )
              ]),
              !_vm.isLoadingOrder
                ? _c(
                    "div",
                    { staticClass: "bg-grey pt-6 px-4" },
                    [
                      _c("OrderTotals", {
                        attrs: { order: _vm.order, useOrderCustomerId: true }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "300" },
              model: {
                value: _vm.isShowingEditQuantityDialog,
                callback: function($$v) {
                  _vm.isShowingEditQuantityDialog = $$v
                },
                expression: "isShowingEditQuantityDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v("Edit Quantity")]),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    [
                      _c("div", { staticClass: "my-8" }),
                      _c("v-text-field", {
                        attrs: {
                          type: "number",
                          label: "Current Quantity",
                          disabled: ""
                        },
                        model: {
                          value: _vm.selectedOrderLine.quantity,
                          callback: function($$v) {
                            _vm.$set(_vm.selectedOrderLine, "quantity", $$v)
                          },
                          expression: "selectedOrderLine.quantity"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: { type: "number", label: "New Quantity" },
                        model: {
                          value: _vm.newQuantity,
                          callback: function($$v) {
                            _vm.newQuantity = $$v
                          },
                          expression: "newQuantity"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            color: "primary",
                            disabled:
                              !_vm.isNewQuantityValid || _vm.isUpdatingQuantity,
                            loading: _vm.isUpdatingQuantity
                          },
                          on: { click: _vm.onConfirmQuantityChange }
                        },
                        [_vm._v("Confirm")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            color: "error",
                            disabled: _vm.isUpdatingQuantity
                          },
                          on: {
                            click: function($event) {
                              _vm.isShowingEditQuantityDialog = false
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "400" },
              model: {
                value: _vm.isShowingConfirmDialog,
                callback: function($$v) {
                  _vm.isShowingConfirmDialog = $$v
                },
                expression: "isShowingConfirmDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v("Remove product")]),
                  _c("v-divider"),
                  _c("v-card-text", [
                    _c("p", { staticClass: "ma-0 pt-5" }, [
                      _vm._v(
                        "Are you sure you want to remove this product from the order?"
                      )
                    ])
                  ]),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            color: "primary",
                            disabled: _vm.isRemovingOrderLine,
                            loading: _vm.isRemovingOrderLine
                          },
                          on: { click: _vm.onConfirmRemoveOrderLine }
                        },
                        [_vm._v("Yes")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            color: "error",
                            disabled: _vm.isRemovingOrderLine
                          },
                          on: {
                            click: function($event) {
                              _vm.isShowingConfirmDialog = false
                            }
                          }
                        },
                        [_vm._v("No")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }