import api from '../api'

export default {
    async reindex() {
        await api.get('product/reindex');
    },

    /**
     * 
     * @param {Number} stockItemId 
     * @returns {Promise<any>}
     */
    async getByStockItemIdWithTags(stockItemId) {
        const response = await api.get(`product/${stockItemId}/tags`);
        console.log("response.data FOR PRODUCT WITH TAGS:", response.data);
        return response.data;
    }
}
