var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fixed-header-wrap" }, [
    _vm.isAnyLicenseExpired
      ? _c(
          "div",
          {
            staticClass: "d-flex justify-center align-center pa-1",
            staticStyle: { "background-color": "#F44336" }
          },
          [
            _c("v-icon", { staticClass: "mr-1", attrs: { dark: "" } }, [
              _vm._v("warning")
            ]),
            _vm._m(0),
            _c(
              "h6",
              { staticClass: "font-weight-regular ma-0 pa-0" },
              [
                _c(
                  "router-link",
                  { attrs: { to: "/account/profile?tab=licenses" } },
                  [_vm._v("View my licenses")]
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "header-wrap primary px-4" },
      [
        _c(
          "v-container",
          { attrs: { "grid-list-xl": "" } },
          [
            _c(
              "v-layout",
              {
                attrs: {
                  "align-center": "",
                  "justify-space-between": "",
                  row: "",
                  "ma-0": ""
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "site-logo d-inline-block" },
                  [
                    _c("router-link", { attrs: { to: "/" } }, [
                      _c("img", {
                        attrs: {
                          alt: "site-logo",
                          height: "85",
                          src: _vm.appLogo,
                          width: "245"
                        }
                      })
                    ])
                  ],
                  1
                ),
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "menu-alignment" },
                    [_c("emb-menu", { attrs: { menus: _vm.menus } })],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "layout align-left responsive-menu" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "toggle-btn",
                          attrs: { icon: "", dark: "" },
                          on: { click: _vm.toggleMobileSidebar }
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("menu")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h6", { staticClass: "font-weight-regular ma-0 pa-0" }, [
      _c("span", { staticClass: "mr-2", staticStyle: { color: "white" } }, [
        _vm._v("One of your licenses is expired or your account is on hold!")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }