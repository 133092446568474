<template>
    <v-card class="px-4 py-2 mb-4" :class="{ 'py-7': !searchBar }">
        <v-row no-gutters>

            <!-- title -->
            <v-col cols="12" sm="3" class="d-flex align-center justify-center justify-sm-start">
                <v-icon class="mr-2">{{icon}}</v-icon>
                <h5 class="ma-0">{{title}}</h5>
                <v-btn v-if="addButton" class="d-sm-none" color="error" icon>
                    <v-icon>add</v-icon>
                </v-btn>
            </v-col><!-- title -->

            <!-- search  -->
            <v-col v-if="searchBar" cols="12" sm="6" class="d-flex justify-center align-center">
                <v-text-field
                    class="mr-4"
                    label="Search"
                    append-icon="search"
                    v-model="searchValue"
                    @change="onSearch"
                    @click:append="onSearch"/>
            </v-col><!-- search  -->

            <!-- refresh -->
            <v-col v-if="refreshButton" cols="12" sm="3" class="d-flex justify-end align-center">
                <v-btn class="d-none d-sm-flex" icon @click="onRefresh">
                    <v-icon>refresh</v-icon>
                </v-btn>
            </v-col><!-- refresh -->

            <!-- add -->
            <v-col v-if="addButton" cols="12" sm="3" class="d-flex justify-end align-center">
                <v-btn class="d-none d-sm-flex" text color="error" @click="onAdd">
                    Add New
                    <v-icon>add</v-icon>
                </v-btn>
            </v-col><!-- add -->

        </v-row>
    </v-card>
</template>

<script>
export default {
    
    props: {
        title: String,
        icon: String,
        addButton: {
            type: Boolean,
            default: false,
        },
        refreshButton: {
            type: Boolean,
            default: false,
        },
        searchBar: {
            type: Boolean,
            default: true,
        },
    }, // props

    data: () => ({
        searchValue: '',
    }), // data

    methods: {
        onAdd() {
            this.$emit('add');
        },

        onRefresh() {
            this.$emit("refresh");
        },

        onSearch() {
            console.log('onSearch()', this.searchValue);
            this.$emit('search', this.searchValue);
        },
    }, // methods
}
</script>
