//-----------------------| Search Module |-------------------//

/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import searchClient from "@/services/azsearch.service";
import api from "@/api";
import Vue from 'vue';

const state = {
  searchString: '*',
  moduleName: 'account',
  searchResults: [],
  searchResultsCount: 0,
  facets: [],
  accounts: [],
  account: { },
  address: {},
  license: {},
  selectedImage: undefined,
  currentAccountId: 0,
  searchParams: {
    pageSize: 10, // page
    pageNumber: 1, // itemsPerPage
    sortField: 'customerName',
    sortDirection: 'ASC',
    searchValue: undefined, // can be either customerName or internalId
  },
  listViewOptions: {
    page: 1, // pageNumber
    itemsPerPage: 10, // pageSize
  },
  surveys: [],
  forms: [],
  submissions: [],
}

const getters = {
  searchString: state => {
    return state.searchString;
  },
  searchResults: state => {
    return state.searchResults;
  },
  searchResultsCount: state => {
    return state.searchResultsCount;
  },
  facets: state => {
    return state.facets;
  },
  getAccounts: state => state.accounts,
  getSearchParams: state => state.searchParams,
  getListViewOptions: state => state.listViewOptions,
  getAccount: state => state.account,
  getSelectedAccountImage: state => state.selectedImage,
  getAddress: state => state.address,
  getLicense: state => state.license,
  getSurveys: state => state.surveys,
  getForms: state => state.forms,
  getFormById: state => formId => state.forms.find(form => form.id == formId),
  getSubmissions: state => state.submissions,
  getSubmissionById: state => submissionId => state.submissions.find(sub => sub.id == submissionId),
}

const actions = {
  get({ state, commit }) {
    return api.get(`${state.moduleName}`, { params: state.searchParams })
    .then(result => commit("SET_ACCOUNTS", result));
  },
  
  getById({ state, commit }) {
    return api.get(`${state.moduleName}/${state.currentAccountId}`).then(result => commit("SET_ACCOUNT_FROM_REQUEST", result));
  },
  
  getByLocation({ state, commit }) {
    return api.get(`${state.moduleName}/location`, { params: state.searchParams })
    .then(result => commit("SET_ACCOUNTS", result));
  },

  search({ state, commit },searchParams) {
    return api.get(`${state.moduleName}/location`, { params: searchParams })
    .then(result => result.data);
  },

  update({ state, commit }) {
    api.put(`${state.moduleName}/${state.currentAccountId}`, state.account).then(result => commit("SET_ACCOUNT_FROM_REQUEST", result));
  },

  create({ state, commit }) {
    api.post(`${state.moduleName}`, state.account).then(result => result);
  },
  
  executeSearch({ state, commit }) {
    console.log('searching')
    searchClient.search(
      "global-catalog",
      {
        search: "bud",
        count: true
      },
      (err, searchResults, raw) => {
        commit("SET_RESULTS", searchResults);
        commit("SET_RESULTS_COUNT", raw["@odata.count"]);
        commit("SET_FACETS", raw["@search.facets"]);
      }
    );
  },

  setSearchString({ dispatch, commit }, value = "*") {
    console.log('searching')
    commit("SET_SEARCHSTRING", value);
    dispatch("executeSearch");
  },

  getSurveysFromApi({ state, commit }) {
    // TODO Get actual result from api

    function getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    let result = [];

    for (let i = 0; i < getRandomInt(6, 10); i++) {
      let item = {
        rbShelves: getRandomInt(1, 10),
        monsterShelves: getRandomInt(0, 8),
        rockstarShelves: getRandomInt(0, 8),
        bangShelves: getRandomInt(0, 8),
      };

      if (item.rbShelves > Math.max(item.monsterShelves, item.rockstarShelves, item.bangShelves)) {
        item.status = 'Winning';
      } else if (item.rbShelves == Math.max(item.monsterShelves, item.rockstarShelves, item.bangShelves)) {
        item.status = 'Tied';
      } else {
        item.status = 'Loosing';
      }

      result.push(item);
    }

    commit('SET_SURVEYS', result);
  },

  getFormsFromApi({ state, commit }) {
    // TODO Get forms from api
  },

  getFormByIdFromApi({ state, commit }, formId) {

  },

  getSubmissionsFromApi({ state, commit }) {
    // TODO Get submissions from api
  },

  getSubmissionsByIdFromApi({ state, commit }, submissionId) {

  },
}

const mutations = {
  SET_RESULTS(state, data) {
    state.searchResults = data;
  },
  SET_SELECTED_ADDRESS(state, data) {
    state.address = Object.assign({}, data);
  },
  SET_SELECTED_LICENSE(state, data) {
    state.license = Object.assign({}, data);
  },
  SET_SEARCH_PARAM_PAGE_SIZE(state, pageSize) {
    state.searchParams.pageSize = pageSize;
  },
  SET_SEARCH_PARAM_PAGE_NUMBER(state, pageNumber) {
      state.searchParams.pageNumber = pageNumber;
  },
  SET_SEARCH_PARAM_SEARCH_VALUE(state, searchValue) {
      state.searchParams.searchValue = searchValue;
  },
  SET_SEARCH_PARAM_SORT_FIELD(state, sortField) {
      state.searchParams.sortField = sortField;
  },
  SET_SEARCH_PARAM_SORT_DIRECTION(state, sortDirection) {
      state.searchParams.sortDirection = sortDirection;
  },
  SET_SEARCH_PARAMS(state, searchParams) {
    state.searchParams = searchParams;
  },
  RESET_SEARCH_PARAMS(state) {
    state.searchParams.pageSize = 10;
    state.searchParams.pageNumber = 1;
    state.searchParams.searchValue = undefined;
    state.searchParams.sortField = 'customerName';
    state.searchParams.sortDirection = 'ASC';
  },
  SET_LIST_VIEW_OPTION_PAGE(state, page) {
    state.listViewOptions.page = page;
  },
  SET_LIST_VIEW_OPTION_ITEMS_PER_PAGE(state, itemsPerPage) {
    state.listViewOptions.itemsPerPage = itemsPerPage;
  },
  SET_ACCOUNT_FROM_REQUEST(state, data) {
    state.account = data.data;
  },
  SET_ACCOUNT(state, data) {
    state.account = data;
  },
  SET_SELECTED_IMAGE(state, data) {
    state.selectedImage = data;
  },
  SET_ACCOUNTS(state, data) {
    state.accounts = data.data;
    Vue.set(state.accounts, 'items', data.data.items);
  },
  SET_CURRENT_ACCOUNT_ID(state, data) {
    state.currentAccountId = data;
  },

  SET_FACETS(state, data) {
    state.facets = data;
  },

  SET_RESULTS_COUNT(state, count) {
    state.searchResultsCount = count;
  },

  SET_SEARCHSTRING(state, value) {
    state.searchString = value;
  },

  SET_SURVEYS(state, data) {
    state.surveys = data;
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
