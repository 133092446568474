<template>
    <div class="px-sm-4 px-md-12" >
        <div v-if="person">
            <v-row>
                <v-col cols="12" class="layout justify-start mt-0 mx-0">
                    <v-icon class="mrz-2" large>person</v-icon>
                    <v-text-field
                        :disabled="isView"
                        class="tab-input-title"
                        v-model="person.fullName"
                        required/>
                </v-col>
            </v-row>

            <v-row class="mb-4">
                <v-col class="pt-0 form-top-back" cols="12">
                    <router-link class="font-weight-medium" to="/admin-panel/people">Back</router-link>
                </v-col>
            </v-row>
            <v-card class="mb-3">
                <v-tabs :vertical="verticalTabs" :centered="!verticalTabs" :center-active="!verticalTabs">
                    <v-tab class="d-flex flex-column flex-md-row justify-md-start justify-center">
                        <v-icon :left="verticalTabs">account_circle</v-icon>
                        Profile
                    </v-tab>
                    <v-tab class="d-flex flex-column flex-md-row justify-md-start justify-center">
                        <v-icon :left="verticalTabs">security</v-icon>
                        Permissions
                    </v-tab>
                    <v-tab-item>
                        <person-overview-tab :selectedPerson="person"></person-overview-tab>
                    </v-tab-item>
                    <v-tab-item>
                        <person-permissions-tab :selectedPerson="person"></person-permissions-tab>
                    </v-tab-item>
                </v-tabs>
            </v-card>

            <div class="d-flex justify-end" v-if="!isView">
                <v-btn :loading="isPerformingOperation" :disabled="isPerformingOperation" color="error" large @click="onClickSave">Save</v-btn>
            </div>
        </div>
        <div v-if="isAdd">
            
            <v-row>
                <v-col cols="12" class="layout justify-start mt-0 mx-0">
                    <v-icon class="mr-2" large>person</v-icon>
                    <v-text-field
                        class="tab-input-title"
                        label="Full Name"
                        v-model="newPerson.fullName"
                        required/>
                </v-col>
            </v-row>
            <v-row class="mb-4">
                <v-col class="py-2 form-top-back" cols="12">
                    <router-link class="font-weight-medium" to="/admin-panel/people">Back</router-link>
                </v-col>
            </v-row>
            <v-card class="mb-3">
                <v-tabs :vertical="verticalTabs" :centered="!verticalTabs" :center-active="!verticalTabs">
                    <v-tab class="d-flex flex-column flex-md-row justify-md-start justify-center">
                        <v-icon :left="verticalTabs">account_circle</v-icon>
                        Profile
                    </v-tab>
                    <v-tab class="d-flex flex-column flex-md-row justify-md-start justify-center">
                        <v-icon :left="verticalTabs">security</v-icon>
                        Permissions
                    </v-tab>
                    <v-tab-item>
                        <person-overview-tab :selectedPerson="newPerson"></person-overview-tab>
                    </v-tab-item>
                    <v-tab-item>
                        <person-permissions-tab :selectedPerson="newPerson"></person-permissions-tab>
                    </v-tab-item>
                </v-tabs>
            </v-card>
            <div class="d-flex justify-end" >
                <v-btn :loading="isPerformingOperation" :disabled="isPerformingOperation" color="error" large @click="onClickSave">Save</v-btn>
            </div>

        </div>

    </div>
    
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PersonOverviewTab from './PersonOverviewTab.vue';
import PersonPermissionsTab from './PersonPermissionsTab.vue';

export default {
    data: () => ({
        isEdit: false,
        isView: false,
        isAdd:false,
        newPerson:{
            fullName:'',
            isPermittedToLogon:false,
            isExternalLogonProvider:false,
            isSystemUser:false,
            isEmployee:false,
            isSalesperson:false,
            isAdmin:false,
            lastEditedBy:1,
            searchName:'',
        },
        personId: undefined,
        verticalTabs: true,
        isPerformingOperation: false,
    }), // data

    computed: {
        ...mapGetters({
            person: 'people/getCurrentPerson',
            session: 'session/getAccount',
        }),
    }, // computed

    beforeMount() {
        this.isEdit = this.$route.path.includes('edit');
        this.isView = this.$route.path.includes('view');
        this.isAdd = this.$route.path.includes('add');
        this.personId = this.$route.params.id;

        if (this.personId) {
            this.getByPersonId(this.personId);
        }
        
        
    }, // beforeMount

    mounted() {
        // watch for window dimensions (for tabs responsiveness)
        this.$nextTick(() => {
            window.addEventListener('resize', () => {
                this.verticalTabs = window.innerWidth >= 960;
            });
        });
        this.verticalTabs = window.innerWidth >= 960;
        if(this.isAdd){
            this.person = null;
        }
    }, // mounted

    methods: {
        ...mapActions({
            getByPersonId: 'people/getByPersonId',
			updatePerson: 'people/update',
            createPerson:'people/post'
        }),

        onClickSave() {
            this.isPerformingOperation = true;
            if(this.isAdd){
                console.log('adding person:', this.newPerson);
                this.createPerson(this.newPerson)
                .then(()=> 
                {   
                    this.$snotify.success('The user has been created!', { timeout: 2000 });
                    this.$router.push('/admin-panel/people');
                    this.newPerson.fullName = null;
                    this.newPerson.isPermittedToLogon = null;
                    this.newPerson.isExternalLogonProvider = null;
                    this.newPerson.isSystemUser = null;
                    this.newPerson.isEmployee = null;
                    this.newPerson.isSalesperson = null;
                    this.newPerson.isAdmin = null;
                    this.newPerson.lastEditedBy = null;
                    this.newPerson.searchName = null;
                })
                .catch(error => console.error(error));
            
            }else{
                console.log('Updating person:', this.person);
    
                this.updatePerson(this.person)
                .then(() => this.$router.go())
                .catch(error => console.error(error));
            }

        },
    }, // methods

    components: {
        PersonOverviewTab,
        PersonPermissionsTab,
    }, // components
}
</script>
