var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gadget-content section-gap" }, [
    _c(
      "div",
      { staticClass: "container grid-list-xl py-0" },
      [
        _vm.order && _vm.order.orderLines && _vm.order.customer
          ? _c(
              "v-card",
              [
                _c(
                  "v-card-text",
                  [
                    _c("h4", { staticClass: "mb-7 text-sm-left text-center" }, [
                      _vm._v("Cart Details")
                    ]),
                    _c("v-flex", { attrs: { xs12: "" } }, [
                      _c(
                        "div",
                        { staticClass: "mt-12 mx-4" },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                row: "",
                                wrap: "",
                                "mb-10": "",
                                "mx-0": "",
                                "mt-0": ""
                              }
                            },
                            [
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    sm6: "",
                                    md6: "",
                                    lg6: "",
                                    xl6: "",
                                    "text-left": "",
                                    "pa-0": ""
                                  }
                                },
                                [
                                  _c("h6", [_vm._v("Summary")]),
                                  _c("p", { staticClass: "mb-1" }, [
                                    _vm._v("Order Date: "),
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.getOrderDate()))
                                    ])
                                  ])
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    sm6: "",
                                    md6: "",
                                    lg6: "",
                                    xl6: "",
                                    "text-left": "",
                                    "pa-0": ""
                                  }
                                },
                                [
                                  _c("h6", [_vm._v("Deliver To")]),
                                  _c("p", { staticClass: "mb-1" }, [
                                    _vm._v(
                                      _vm._s(_vm.order.customer.customerName)
                                    )
                                  ]),
                                  _c("p", { staticClass: "mb-1" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.order.customer.deliveryAddressLine1
                                      ) +
                                        ", " +
                                        _vm._s(
                                          _vm.order.customer.deliveryCity
                                            .cityName
                                        )
                                    )
                                  ]),
                                  _c("p", { staticClass: "mb-1" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.order.customer.deliveryAddressLine2
                                      )
                                    )
                                  ]),
                                  _c("p", { staticClass: "mb-1" }, [
                                    _vm._v(
                                      "Contact No. " +
                                        _vm._s(_vm.order.customer.phoneNumber)
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "py-6 text-center bg-grey" },
                            [
                              _c("h4", [_vm._v("Expected Date of Delivery")]),
                              _c("h3", [
                                _vm._v(
                                  _vm._s(_vm.getOrderExpectedDeliveryDate())
                                )
                              ])
                            ]
                          ),
                          _c(
                            "h4",
                            {
                              staticClass: "pt-12 mb-7 text-sm-left text-center"
                            },
                            [_vm._v("Your Cart List")]
                          ),
                          _c(
                            "div",
                            { staticClass: "mb-6" },
                            _vm._l(_vm.order.orderLines, function(
                              orderLine,
                              index
                            ) {
                              return _c(
                                "div",
                                { key: index },
                                [
                                  _c("OrderLineItem", {
                                    ref:
                                      "orderLineItem-" + orderLine.orderLineId,
                                    refInFor: true,
                                    attrs: {
                                      canEditQuantity: false,
                                      canRemove: false,
                                      item: orderLine,
                                      order: _vm.order
                                    }
                                  }),
                                  _c("v-divider")
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { sm: "12", md: "7" } }),
                              _c(
                                "v-col",
                                { attrs: { sm: "12", md: "5" } },
                                [
                                  _c("OrderTotals", {
                                    attrs: {
                                      order: _vm.order,
                                      amountSubtotal: _vm.orderSubTotal,
                                      amountDeposits: _vm.orderDeposits,
                                      amountDiscounts: _vm.orderDiscounts,
                                      amountTotal: _vm.orderTotal
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "v-card-actions",
                  { staticClass: "d-flex justify-end pb-6 px-6" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "accent",
                        attrs: {
                          large: "",
                          disabled: _vm.isPlacingOrder,
                          loading: _vm.isPlacingOrder
                        },
                        on: {
                          click: function($event) {
                            return _vm.createOrder()
                          }
                        }
                      },
                      [_vm._v("Place Order")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }