var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "pa-4 mb-4" },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "d-flex align-center justify-center justify-sm-start",
                  attrs: { cols: "12", sm: "3" }
                },
                [
                  _c("v-icon", { staticClass: "mr-1" }, [_vm._v("bar_chart")]),
                  _c("h5", { staticClass: "ma-0" }, [_vm._v("Reports")]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "d-sm-none",
                      attrs: {
                        color: "error",
                        icon: "",
                        to: "/admin-panel/reports/add"
                      }
                    },
                    [_c("v-icon", [_vm._v("add")])],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-center align-center",
                  attrs: { cols: "12", sm: "6" }
                },
                [
                  _c("v-text-field", {
                    staticClass: "mr-4",
                    attrs: { label: "Search Reports" }
                  }),
                  _c("v-btn", { attrs: { color: "primary" } }, [
                    _vm._v("Search")
                  ])
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-end align-center",
                  attrs: { cols: "12", sm: "3" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "d-none d-sm-flex",
                      attrs: { color: "error", to: "/admin-panel/reports/add" }
                    },
                    [_vm._v(" Add "), _c("v-icon", [_vm._v("add")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("reports-list-view", { attrs: { isLoading: false } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }