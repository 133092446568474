var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lightening-deals-wrap section-gap pb-0" },
    [
      _c("v-container", { attrs: { "grid-list-xl": "" } }, [
        _c("div", { staticClass: "sec-wrap primary py-4 px-6" }, [
          _c(
            "div",
            {
              staticClass:
                "layout align-center c-layout justify-space-between pa-4"
            },
            [
              _c("div", { staticClass: "sec-title" }, [
                _c("h2", { staticClass: "white--text mb-0 text-xxl" }, [
                  _vm._v(_vm._s(_vm.title))
                ])
              ]),
              !_vm.isLoading && _vm.items && _vm.items.length != 0
                ? _c(
                    "span",
                    {
                      staticClass:
                        "white--text text-sm text-uppercase font-weight-medium cursor-pointer",
                      on: {
                        click: function($event) {
                          return _vm.$emit("view-all", _vm.title)
                        }
                      }
                    },
                    [_vm._v(" View all ")]
                  )
                : _vm._e()
            ]
          )
        ]),
        _c(
          "div",
          { staticClass: "shop-card-gap px-6" },
          [
            _vm.isLoading
              ? _c("v-progress-linear", {
                  attrs: { indeterminate: "", color: "white" }
                })
              : _vm._e(),
            !_vm.isLoading && _vm.items && _vm.items.length != 0
              ? _c(
                  "v-layout",
                  { attrs: { row: "", wrap: "" } },
                  _vm._l(_vm.items, function(item, index) {
                    return _c(
                      "v-flex",
                      {
                        key: index,
                        attrs: { xs12: "", sm6: "", md6: "", lg3: "", xl3: "" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "emb-card box-shadow-md pa-6 white" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "text-decoration-none",
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("item-clicked", item)
                                  }
                                }
                              },
                              [
                                _c("img", {
                                  staticClass: "d-block mx-auto",
                                  staticStyle: { height: "280px" },
                                  attrs: { alt: "Product", src: item.thumbnail }
                                })
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "emb-card-content pt-4" },
                              [
                                _c("h5", { staticClass: "h5-fixed-lines-3" }, [
                                  _vm._v(_vm._s(item.name))
                                ]),
                                _c("div", { staticClass: "d-flex" }, [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "text-muted cursor-pointer",
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit(
                                            "category-clicked",
                                            item.categories
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(item.categories))]
                                  )
                                ]),
                                _c("p", { staticClass: "accent--text mb-0" }, [
                                  _vm._v(
                                    "$ " +
                                      _vm._s(
                                        _vm.$numformat.currencyFormat(
                                          item.unitPrice
                                        )
                                      )
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  1
                )
              : _vm._e()
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }