<template>
  <div>
    
    <!-- search bar -->
    <v-card class="pa-4 mb-4">
      <v-row no-gutters>
        <v-col cols="12" sm="3" class="d-flex align-center justify-center justify-sm-start">
          <v-icon class="mr-1">account_box</v-icon>
          <h5 class="ma-0">Accounts</h5>
        </v-col>
        <v-col cols="12" sm="6" class="d-flex justify-center align-center">
          <v-text-field class="mr-4" label="Search Accounts" v-model="searchValue" @change="executeSearch"/>
          <v-btn color="primary" @click="executeSearch">Search</v-btn>
        </v-col>
      </v-row>
    </v-card><!-- search bar -->
    
    <account-items-list-view
      :isLoading="isLoading"
      :paginatedList="accountsPaginatedList"
      :searchParams="searchParams"
      v-on:search-params-changed="onSearchParamsChanged">
    </account-items-list-view>

  </div>
</template>

<script>
import AccountItemsListView from './AccountItemsListView';
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  
  data() {
    return {
      isLoading: true,
      searchValue: undefined,
    };
  }, // data

  computed: {
    ...mapGetters({
      currentLocation: 'session/getLocation',
      accountsPaginatedList: 'accounts/getAccounts',
      searchParams: 'accounts/getSearchParams',
    }),
  }, // computed

  mounted() {
    let resetSearchParams = this.$route.query.resetSearchParams;
    if (resetSearchParams && resetSearchParams.toString() == true.toString()) this.resetSearchParams();

    this.searchValue = this.searchParams.searchValue;
    this.getAccountsByLocation().then(() => this.isLoading = false);
  }, // mounted

  methods: {
    ...mapMutations({
      setSearchValue: 'accounts/SET_SEARCH_PARAM_SEARCH_VALUE',
      setSearchParams: 'accounts/SET_SEARCH_PARAMS',
      resetSearchParams: 'accounts/RESET_SEARCH_PARAMS',
    }),

    ...mapActions({
      getAccountsByLocation: 'accounts/getByLocation',
    }),

    executeSearch() {
      this.isLoading = true;
      this.setSearchValue(this.searchValue);

      this.getAccountsByLocation().then(() => this.isLoading = false);
    },

    onSearchParamsChanged(searchParams) {
      this.isLoading = true;
      this.setSearchParams(searchParams);
      this.setSearchValue(this.searchValue);

      this.getAccountsByLocation().then(() => this.isLoading = false);
    },
  }, // methods

  components: {
    AccountItemsListView,
  }, // components
};
</script>

