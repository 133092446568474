var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex justify-end align-center mb-3" },
        [
          _c(
            "v-btn",
            { attrs: { icon: "" }, on: { click: _vm.refresh } },
            [_c("v-icon", [_vm._v("refresh")])],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.orders.items,
              loading: _vm.isLoadingOrders,
              "hide-default-footer": ""
            },
            scopedSlots: _vm._u([
              {
                key: "item.createdDate",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(" " + _vm._s(_vm.getOrderCreatedDate(item)) + " ")
                  ]
                }
              },
              {
                key: "item.expectedDeliveryDate",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.getOrderExpectedDeliveryDate(item)) + " "
                    )
                  ]
                }
              },
              {
                key: "item.lastEditedDate",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(" " + _vm._s(_vm.getOrderLastEditedDate(item)) + " ")
                  ]
                }
              },
              {
                key: "item.lastEditedBy",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(" " + _vm._s(_vm.getLastEditedByPerson(item)) + " ")
                  ]
                }
              },
              {
                key: "item.status",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.isOrderSubmitted
                      ? _c("span", { staticStyle: { color: "green" } }, [
                          _vm._v("Submitted")
                        ])
                      : _c("span", { staticStyle: { color: "gray" } }, [
                          _vm._v("Pending")
                        ])
                  ]
                }
              },
              {
                key: "item.action",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-btn",
                      {
                        staticClass: "text--accent-1",
                        attrs: { small: "", icon: "" },
                        on: {
                          click: function($event) {
                            return _vm.showOrderViewDialog(item)
                          }
                        }
                      },
                      [_c("v-icon", [_vm._v("visibility")])],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "980" },
          model: {
            value: _vm.isShowingOrderViewDialog,
            callback: function($$v) {
              _vm.isShowingOrderViewDialog = $$v
            },
            expression: "isShowingOrderViewDialog"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c(
                "v-card-text",
                { staticClass: "pt-6" },
                [
                  _c("account-order-view", {
                    attrs: { order: _vm.lastSelectedOrder }
                  })
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-end" },
                [
                  !_vm.isAnyLicenseExpired
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "error" },
                          on: {
                            click: function($event) {
                              return _vm.showReorderDialog(
                                _vm.lastSelectedOrder
                              )
                            }
                          }
                        },
                        [_vm._v("Reorder")]
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.isShowingOrderViewDialog = false
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.isShowingReorderDialog,
            callback: function($$v) {
              _vm.isShowingReorderDialog = $$v
            },
            expression: "isShowingReorderDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c("v-card-title", [
                    _vm._v("Do you want to make this order again?")
                  ]),
                  _c("p", [
                    _vm._v(
                      "All the products from this order will be added to your cart. You can then continue adding more products or place your order."
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "error",
                        loading: _vm.isDoingReorder,
                        disabled: _vm.isDoingReorder
                      },
                      on: {
                        click: function($event) {
                          return _vm.reorder(_vm.orderToReorder)
                        }
                      }
                    },
                    [_vm._v(" Yes ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", disabled: _vm.isDoingReorder },
                      on: {
                        click: function($event) {
                          _vm.isShowingReorderDialog = false
                        }
                      }
                    },
                    [_vm._v("No")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }