/*eslint-disable*/

import api from '@/api';

export default { 
    namespaced: true,

    state: {
        moduleName: 'tradingPartner',
        partners:[],
        partnersList:[],
        searchParams: {
            pageSize: 10,
            pageNumber: 1,
            sortField: 'x12tradingPartnerDescription',
            sortDirection: 'ASC',
            searchValue: undefined,
        },
    }, // state

    getters: {
        getSearchParams: state => state.searchParams,
    }, // getters

    mutations: {
        SET_PARTNERS(state, data) {
            state.partners = data.data;
        },
        SET_SEARCH_PARAMS(state, searchParams) {
            state.searchParams = searchParams;
        },
        SET_SEARCH_PARAM_SEARCH_VALUE(state, searchValue) {
            state.searchValue = searchValue;
        },
        RESET_SEARCH_PARAMS(state) {
            state.searchParams.pageSize = 10;
            state.searchParams.pageNumber = 1;
            state.searchParams.searchValue = undefined;
            state.searchParams.sortField = 'x12tradingPartnerDescription';
            state.searchParams.sortDirection = 'ASC';
        },
    }, // mutations
    
    actions: {
        async get({ state, commit }) {
            const response = await api.get(`${state.moduleName}`, { params: state.searchParams });
            const partners = response.data;
            commit('SET_PARTNERS', partners)
            return partners;
        },
        async getMessages({ state, commit },searchParams) {
            const response = await  api.get(`${state.moduleName}/messages`, { params: searchParams })
            return response.data;
        },
        async getMessagesById({ state, commit },id) {
            const response = await  api.get(`${state.moduleName}/messages/${id}`)
            return response.data;
        },
        async delete({ state, commit },id) {
            const response = await  api.delete(`${state.moduleName}/${id}`)
            return response.data;

        },
        async post({ state, commit },partner) {
            const response = await  api.post(`${state.moduleName}`, partner)
            return response.data;

        },
        async getById({ state, commit },id) {
            const response = await  api.get(`${state.moduleName}/${id}`)
            return response.data;

        },
        async update({ state, commit },partner) {
            const response = await api.put(`${state.moduleName}/${partner.x12tradingPartnerId}`,partner);
            return response.data;
        },

        
    }
}