/*eslint-disable*/

import { msalConfig, tokenRequest } from '../constants/azure-ad-config';
import * as Msal from 'msal';
import { store } from '../store/store';

export class MsalService {

    constructor() {
        this.msal = new Msal.UserAgentApplication(msalConfig);
        this.msal.handleRedirectCallback(
            token => {
                const gotFromRedirect = sessionStorage.getItem("fb.gotTokenWithRedirect");

                if (gotFromRedirect != "true") {
                    return;
                }

                console.log("Got access token from redirect!", token);
                
                const accessToken = token.accessToken;
                const expirationTime = token.expiresOn;

                sessionStorage.setItem("fb.accessToken", accessToken);
                sessionStorage.setItem("fb.expirationTime", expirationTime);
            },
            error => {
                console.error("Could not get access token from redirect.", error);
            }
        );
    }

    loginPopup() {
        return this.msal.loginPopup();
    }

    loginRedirect() {
        this.msal.loginRedirect();
    }

    logoutAsync() {
        return this.msal.logoutAsync();
    }

    logout() {
        sessionStorage.removeItem('fb.accessToken');
        sessionStorage.removeItem('fb.expirationTime');
        sessionStorage.removeItem("fb.gotTokenWithRedirect");
        store.commit('session/SET_PERSON', undefined);
        store.commit('session/SET_ACCOUNT', undefined);
        store.commit('session/SET_LOCATION', undefined);
        this.msal.logout();
    }

    isAuthenticated() {
        let token = this.msal.getAccount();

        if (!token)
            return false;

        return true;
    }

    isPermittedToLogon() {
        let person = store.getters['session/getPerson'];

        if (!person)
            return false;

        if (!person.isPermittedToLogon) {
            return false;
        }
        
        return true;
    }

    isAccountSelected() {
        let account = store.getters['session/getAccount'];

        if (!account)
            return false;
        
        return true;
    }

    isAnyLicenseExpired() {
        return store.getters['session/isAnyLicenseExpired'];
    }

    /**
     * Gets token containing information about current signed in user.
     * Returns null if no user has been signed in.
     */
    getAccountToken() {
        return this.msal.getAccount();
    }

    /**
     * Gets the access token for the bearer
     */
    getAccessToken() {
        console.log('acquireTokenSilent', tokenRequest);
        return this.msal.acquireTokenSilent(tokenRequest);
    }

    /**
     * Use when getAccessToken throws InteractionRequiredAuthError
     */
    getAccessTokenPopup() {
        console.log('acquireTokenPopup', tokenRequest);
        return this.msal.acquireTokenPopup(tokenRequest);
    }

    getAccessTokenRedirect() {
        console.log("acquireTokenRedirect", tokenRequest);
        this.msal.acquireTokenRedirect(tokenRequest);
    }

    getPerson() {
        return store.getters['session/getPerson'];
    }

    getAccount() {
        return store.getters['session/getAccount'];
    }

    getLocation() {
        return store.getters['session/getLocation'];
    }
}

/*
    https://fbb2cdevscus.b2clogin.com/fbb2cdevscus.onmicrosoft.com/b2c_1_portal_signup_signin/oauth2/v2.0/authorize?
        response_type=token&
        scope=https%3A%2F%2Ffbb2cdevscus.onmicrosoft.com%2Ffb-api%2Fuser_impersonation%20openid%20profile&
        client_id=5d704efe-7397-4a42-80d4-d77f96bb63a7&
        redirect_uri=http%3A%2F%2Flocalhost%3A8080%2Flogin&
        state=eyJpZCI6IjNkMTRkNGUyLTYzYjEtNDBkNC05N2EzLTE4Y2E3YzNhMmJkOSIsInRzIjoxNTk5Nzc1ODE5LCJtZXRob2QiOiJzaWxlbnRJbnRlcmFjdGlvbiJ9&
        nonce=9d668bc2-2a1a-4971-a9c8-81faff1c713a&
        client_info=1&
        x-client-SKU=MSAL.JS&
        x-client-Ver=1.3.3&
        client-request-id=f2ef359a-41cd-442a-bdc9-9afe48f87cda&
        prompt=none&
        response_mode=fragment
*/
