var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "px-sm-4 px-md-12" }, [
    _vm.person
      ? _c(
          "div",
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  {
                    staticClass: "layout justify-start mt-0 mx-0",
                    attrs: { cols: "12" }
                  },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mrz-2", attrs: { large: "" } },
                      [_vm._v("person")]
                    ),
                    _c("v-text-field", {
                      staticClass: "tab-input-title",
                      attrs: { disabled: _vm.isView, required: "" },
                      model: {
                        value: _vm.person.fullName,
                        callback: function($$v) {
                          _vm.$set(_vm.person, "fullName", $$v)
                        },
                        expression: "person.fullName"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              { staticClass: "mb-4" },
              [
                _c(
                  "v-col",
                  { staticClass: "pt-0 form-top-back", attrs: { cols: "12" } },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "font-weight-medium",
                        attrs: { to: "/admin-panel/people" }
                      },
                      [_vm._v("Back")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-card",
              { staticClass: "mb-3" },
              [
                _c(
                  "v-tabs",
                  {
                    attrs: {
                      vertical: _vm.verticalTabs,
                      centered: !_vm.verticalTabs,
                      "center-active": !_vm.verticalTabs
                    }
                  },
                  [
                    _c(
                      "v-tab",
                      {
                        staticClass:
                          "d-flex flex-column flex-md-row justify-md-start justify-center"
                      },
                      [
                        _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                          _vm._v("account_circle")
                        ]),
                        _vm._v(" Profile ")
                      ],
                      1
                    ),
                    _c(
                      "v-tab",
                      {
                        staticClass:
                          "d-flex flex-column flex-md-row justify-md-start justify-center"
                      },
                      [
                        _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                          _vm._v("security")
                        ]),
                        _vm._v(" Permissions ")
                      ],
                      1
                    ),
                    _c(
                      "v-tab-item",
                      [
                        _c("person-overview-tab", {
                          attrs: { selectedPerson: _vm.person }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-tab-item",
                      [
                        _c("person-permissions-tab", {
                          attrs: { selectedPerson: _vm.person }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            !_vm.isView
              ? _c(
                  "div",
                  { staticClass: "d-flex justify-end" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          loading: _vm.isPerformingOperation,
                          disabled: _vm.isPerformingOperation,
                          color: "error",
                          large: ""
                        },
                        on: { click: _vm.onClickSave }
                      },
                      [_vm._v("Save")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    _vm.isAdd
      ? _c(
          "div",
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  {
                    staticClass: "layout justify-start mt-0 mx-0",
                    attrs: { cols: "12" }
                  },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mr-2", attrs: { large: "" } },
                      [_vm._v("person")]
                    ),
                    _c("v-text-field", {
                      staticClass: "tab-input-title",
                      attrs: { label: "Full Name", required: "" },
                      model: {
                        value: _vm.newPerson.fullName,
                        callback: function($$v) {
                          _vm.$set(_vm.newPerson, "fullName", $$v)
                        },
                        expression: "newPerson.fullName"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              { staticClass: "mb-4" },
              [
                _c(
                  "v-col",
                  { staticClass: "py-2 form-top-back", attrs: { cols: "12" } },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "font-weight-medium",
                        attrs: { to: "/admin-panel/people" }
                      },
                      [_vm._v("Back")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-card",
              { staticClass: "mb-3" },
              [
                _c(
                  "v-tabs",
                  {
                    attrs: {
                      vertical: _vm.verticalTabs,
                      centered: !_vm.verticalTabs,
                      "center-active": !_vm.verticalTabs
                    }
                  },
                  [
                    _c(
                      "v-tab",
                      {
                        staticClass:
                          "d-flex flex-column flex-md-row justify-md-start justify-center"
                      },
                      [
                        _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                          _vm._v("account_circle")
                        ]),
                        _vm._v(" Profile ")
                      ],
                      1
                    ),
                    _c(
                      "v-tab",
                      {
                        staticClass:
                          "d-flex flex-column flex-md-row justify-md-start justify-center"
                      },
                      [
                        _c("v-icon", { attrs: { left: _vm.verticalTabs } }, [
                          _vm._v("security")
                        ]),
                        _vm._v(" Permissions ")
                      ],
                      1
                    ),
                    _c(
                      "v-tab-item",
                      [
                        _c("person-overview-tab", {
                          attrs: { selectedPerson: _vm.newPerson }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-tab-item",
                      [
                        _c("person-permissions-tab", {
                          attrs: { selectedPerson: _vm.newPerson }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex justify-end" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      loading: _vm.isPerformingOperation,
                      disabled: _vm.isPerformingOperation,
                      color: "error",
                      large: ""
                    },
                    on: { click: _vm.onClickSave }
                  },
                  [_vm._v("Save")]
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }