<template>
  <div class="gadget-content section-gap">
    <div class="container grid-list-xl py-0">
      <v-card v-if="order && order.orderLines && order.customer">
        <v-card-text>
          <h4 class="mb-7 text-sm-left text-center">Cart Details</h4>
          <v-flex xs12>
            <div class="mt-12 mx-4">
              <v-layout row wrap mb-10 mx-0 mt-0>

                <!-- summary -->
                <v-flex xs12 sm6 md6 lg6 xl6 text-left pa-0>
                  <h6>Summary</h6>
                  <p class="mb-1">Order Date: <b>{{getOrderDate()}}</b></p>
                </v-flex><!-- summary -->

                <!-- deliver to -->
                <v-flex xs12 sm6 md6 lg6 xl6 text-left pa-0>
                  <h6>Deliver To</h6>
                  <p class="mb-1">{{order.customer.customerName}}</p>
                  <p class="mb-1">{{order.customer.deliveryAddressLine1}}, {{order.customer.deliveryCity.cityName}}</p>
                  <p class="mb-1">{{order.customer.deliveryAddressLine2}}</p>
                  <p class="mb-1">Contact No. {{order.customer.phoneNumber}}</p>
                </v-flex><!-- deliver to -->

              </v-layout>

              <!-- expected delivery date -->
              <div class="py-6 text-center bg-grey">
                <h4>Expected Date of Delivery</h4>
                <h3>{{getOrderExpectedDeliveryDate()}}</h3>
              </div><!-- expected delivery date -->

              <h4 class="pt-12 mb-7 text-sm-left text-center">Your Cart List</h4>

              <!-- order lines -->
              <div class="mb-6">
                <div v-for="(orderLine, index) of order.orderLines" :key="index">
                  <OrderLineItem
                    :ref="`orderLineItem-${orderLine.orderLineId}`"
                    :canEditQuantity="false"
                    :canRemove="false"
                    :item="orderLine"
                    :order="order"/>
                  <v-divider/>
                </div>
              </div><!-- order lines -->

              <!-- order totals -->
              <v-row>

                <!-- spacer -->
                <v-col sm="12" md="7">
                </v-col><!-- spacer -->

                <!-- totals -->
                <v-col sm="12" md="5">
                  <OrderTotals :order="order"
                    :amountSubtotal="orderSubTotal"
                    :amountDeposits="orderDeposits"
                    :amountDiscounts="orderDiscounts"
                    :amountTotal="orderTotal"/>
                </v-col><!-- totals -->

              </v-row><!-- order totals -->

            </div>
          </v-flex>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-6 px-6">
          <v-btn class="accent" large :disabled="isPlacingOrder" :loading="isPlacingOrder" @click="createOrder()">Place Order</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from "vuex";
import { currency, orders, utils } from "../mixins";
import OrderLineItem from '@/components/OrderLineItem';
import OrderTotals from '@/components/OrderTotals';

export default {

  mixins: [currency, orders, utils],

  data() {
    return {
      checkbox: false,
      isPlacingOrder: false,
      orderSubTotal: undefined,
      orderDeposits: undefined,
      orderDiscounts: undefined,
      orderTotal: undefined,
    };
  }, // data

  mounted() {
    if (this.order) {
      
      this.$nextTick(() => {
        this.orderSubTotal = this.getOrderSubTotal();
        this.orderDeposits = this.getOrderDeposits();
        this.orderDiscounts = this.getOrderDiscounts();
        this.orderTotal = this.getOrderTotal();
      });
    }
    console.log(this.order.orderLines);
  }, // mounted

  computed: {
    ...mapGetters({
      order: "order/getCart",
    }),
  }, // computed

  methods: {
    ...mapActions({
      placeOrder: 'order/place',
      sendOrderConfirmationEmail: 'email/sendOrderConfirmationEmail',
    }),
    
    createOrder() {
      const cartSnapshot = this.createCartSnapshot(); // We need a copy for the email because after placing the order, all the order data is reset
      const orderIdSnapshot = JSON.parse(JSON.stringify(this.order)).orderId;
      
      
      this.isPlacingOrder = true;
      this.placeOrder()
      .then(() => {
        this.$snotify.success('Your order has been placed!', { timeout: 2000 });
        this.$router.push('/products');
        
        this.sendOrderConfirmationEmailRequest({
          orderId:orderIdSnapshot,
          cartSnapshot
        });
      })
      .catch(error => this.$snotify.error(`Could not place order. ${error}`, {
        closeOnClick: false,
        pauseOnHover: true,
        showProgressBar: true,
        timeout: 5000,
      }))
      .finally(() => this.isPlacingOrder = false);
    },
    createCartSnapshot(){
      const items = [];
      for (const orderLine of this.order.orderLines) {
        const orderLineItemRef = `orderLineItem-${orderLine.orderLineId}`;
        const orderLineItem = this.$refs[orderLineItemRef][0];
        const orderLineSnapshot = 
        {
          id: orderLine.stockItem.internalId,
          description: orderLine.stockItem.stockItemName,
          quantity: orderLine.quantity,
          unitPrice: this.formatNumber(orderLine.baseUnitPrice),
          deposit: this.formatNumber(orderLine.depositPrice),
          discount: this.formatNumber(orderLineItem.discount),
          subTotal: this.formatNumber(orderLineItem.subTotal)
        }
        items.push(orderLineSnapshot);
      }
      return items;
    },

    getOrderDate() {
      if (!this.order || !this.order.orderDate) {
        return '';
      }
      let dateUtc = this.order.orderDate.toString();
      return utils.methods.getFormattedDateUtcShortForOrderDate(dateUtc);
    },

    getOrderExpectedDeliveryDate() {
      if (!this.order || !this.order.expectedDeliveryDate) {
        return '';
      }

      const dateExpected = new Date();
      dateExpected.setDate(dateExpected.getDate() + 1);

      return utils.methods.getFormattedDateUtcShort(dateExpected);
    },

    sendOrderConfirmationEmailRequest(order) {
      this.sendOrderConfirmationEmail(order)
      .then(() => {
        this.$snotify.success("An order confirmation email has been sent to you! Check your inbox to see your order details.", {
          closeOnClick: false,
          pauseOnHover: true,
          showProgressBar: true,
          timeout: 4000,
        });
      })
      .catch(error => {
        console.error('Could not send order confirmation email:', error);
      });
    },

    getOrderSubTotal() {
      let orderSubTotal = 0;

      for (const orderLine of this.order.orderLines) {
        const orderLineItemRef = `orderLineItem-${orderLine.orderLineId}`;
        const orderLineItem = this.$refs[orderLineItemRef][0];
        orderSubTotal += orderLineItem.unitPrice * orderLineItem.quantity;
      }

      return orderSubTotal;
    },

    getOrderDeposits() {
      let orderDeposits = 0;

      for (const orderLine of this.order.orderLines) {
        const orderLineItemRef = `orderLineItem-${orderLine.orderLineId}`;
        const orderLineItem = this.$refs[orderLineItemRef][0];
        orderDeposits += orderLineItem.depositPrice * orderLineItem.quantity;
      }

      return orderDeposits;
    },

    getOrderDiscounts() {
      let orderDiscounts = 0;

      for (const orderLine of this.order.orderLines) {
        const orderLineItemRef = `orderLineItem-${orderLine.orderLineId}`;
        const orderLineItem = this.$refs[orderLineItemRef][0];
        orderDiscounts += orderLineItem.discount * orderLineItem.quantity;
      }

      return orderDiscounts;
    },

    getOrderTotal() {
      return this.getOrderSubTotal() + this.getOrderDeposits() - this.getOrderDiscounts();
    },
  }, // methods

  watch: {
    order() {
      this.$nextTick(() => {
        this.orderSubTotal = this.getOrderSubTotal();
        this.orderDeposits = this.getOrderDeposits();
        this.orderDiscounts = this.getOrderDiscounts();
        this.orderTotal = this.getOrderTotal();
      });
    },
  }, // watch

  components: {
    OrderLineItem,
    OrderTotals,
  }, // components
};
</script>
