<template>
  <div>

    <!-- header -->
    <v-card class="pa-4 mb-4">

      <v-row no-gutters>
        <v-col cols="12" sm="3" class="d-flex align-center justify-center justify-sm-start">
          <v-icon class="mr-1">shopping_cart</v-icon>
          <h5 class="ma-0">Products</h5>
          <v-btn class="d-sm-none" color="error" icon to="/admin-panel/product-add">
            <v-icon>add</v-icon>
          </v-btn>
        </v-col>
        
        <!-- search bar -->
        <v-col cols="12" sm="6" class="d-flex justify-center align-center">
          <v-text-field class="mr-4" label="Search Products" v-model="searchValue" @change="executeSearch"/>
          <v-btn color="primary" @click="executeSearch">Search</v-btn>
        </v-col><!-- search bar -->

        <v-col cols="12" sm="3" class="d-flex justify-end align-center">

          <!-- add new -->
          <v-btn class="d-none d-sm-flex" color="error" to="/admin-panel/product-add">
            Add New
            <v-icon right>add</v-icon>
          </v-btn><!-- add new -->

        </v-col>
      </v-row>

    </v-card><!-- header -->

    <stock-holdings-list-view
      :isLoading="isLoading"
      :paginatedList="stockHoldingsPaginatedList"
      :searchParams="searchParams"
      v-on:search-params-changed="onSearchParamsChanged">
    </stock-holdings-list-view>

  </div>
</template>

<script>
import StockHoldingsListView from './ProductStockHoldingsItemsListView';
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {

  data() {
    return {
      isLoading: false,
      searchValue: undefined,
    };
  }, // data

  computed: {
    ...mapGetters({
      currentLocation: 'session/getLocation',
      stockHoldingsPaginatedList: 'stockHoldings/getStockHoldings',
      searchParams: 'stockHoldings/getSearchParams',
    }),
  }, // computed

  mounted() {
    let resetSearchParams = this.$route.query.resetSearchParams;
    if (resetSearchParams && resetSearchParams.toString() == true.toString()) {
      this.resetSearchParams();
    }

    this.searchValue = this.searchParams.searchValue;
    this.getStockHoldingsByLocation().then(() => this.isLoading = false);
  }, // mounted

  methods: {
    ...mapMutations({
      setSearchValue: 'stockHoldings/SET_SEARCH_PARAM_SEARCH_VALUE',
      setSearchParams: 'stockHoldings/SET_SEARCH_PARAMS',
      resetSearchParams: 'stockHoldings/RESET_SEARCH_PARAMS',
    }),

    ...mapActions({
      getStockHoldingsByLocation: 'stockHoldings/getByLocation',
    }),
    
    executeSearch() {
      this.isLoading = true;
      this.setSearchValue(this.searchValue);

      this.getStockHoldingsByLocation().then(() => this.isLoading = false);
    },

    onSearchParamsChanged(searchParams) {
      this.isLoading = true;
      this.setSearchParams(searchParams);
      this.setSearchValue(this.searchValue);

      this.getStockHoldingsByLocation().then(() => this.isLoading = false);
    },
  }, // methods

  components: {
    StockHoldingsListView,
  }, // components
};
</script>
