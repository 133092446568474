var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gadget-content" },
    [
      _c("emb-page-title", {
        attrs: { heading: "Choose an account", imageType: "my-account" }
      }),
      _c("div", { staticClass: "container py-12" }, [
        _c(
          "div",
          { staticClass: "mb-12" },
          [
            _c("h5", { staticClass: "font-weight-light mb-3" }, [
              _vm._v("Your current account is")
            ]),
            _c(
              "v-card",
              { staticClass: "mb-6" },
              [
                _c("v-card-text", [
                  _vm.currentAccount
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "d-flex align-center mb-3" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-2",
                                staticStyle: { "font-size": "30px" }
                              },
                              [_vm._v("account_circle")]
                            ),
                            _c(
                              "h5",
                              { staticClass: "font-weight-regular ma-0" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.currentAccount.customerName) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex align-center mb-3" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-2",
                                staticStyle: { "font-size": "30px" }
                              },
                              [_vm._v("local_shipping")]
                            ),
                            _c(
                              "h5",
                              { staticClass: "font-weight-light ma-0" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.getAddress(_vm.currentAccount)) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex align-center" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-2",
                                staticStyle: { "font-size": "27px" }
                              },
                              [_vm._v("tag")]
                            ),
                            _c(
                              "h5",
                              { staticClass: "font-weight-light ma-0" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.currentAccount.internalId) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    : _c("div", [
                        _c(
                          "div",
                          { staticClass: "d-flex align-center" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-2",
                                staticStyle: { "font-size": "30px" }
                              },
                              [_vm._v("error_outline")]
                            ),
                            _c(
                              "h5",
                              { staticClass: "font-weight-regular ma-0" },
                              [_vm._v("No Account Selected")]
                            )
                          ],
                          1
                        )
                      ])
                ])
              ],
              1
            )
          ],
          1
        ),
        _c("div", [
          _c("h5", { staticClass: "font-weight-light mb-3" }, [
            _vm._v("Select another account")
          ]),
          _c(
            "div",
            {},
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-center align-center",
                  attrs: { cols: "12", sm: "5" }
                },
                [
                  _c("v-text-field", {
                    staticClass: "mr-4",
                    attrs: { label: "Search Account" },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchAccountsRequest($event)
                      }
                    },
                    model: {
                      value: _vm.searchValue,
                      callback: function($$v) {
                        _vm.searchValue = $$v
                      },
                      expression: "searchValue"
                    }
                  }),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.searchAccountsRequest }
                    },
                    [_vm._v("Search")]
                  )
                ],
                1
              )
            ],
            1
          ),
          !_vm.searchResult
            ? _c(
                "div",
                { staticClass: "d-flex flex-column align-center" },
                [
                  _c("p", [_vm._v("Loading accounts")]),
                  _c("v-progress-circular", { attrs: { indeterminate: "" } })
                ],
                1
              )
            : _c(
                "div",
                _vm._l(_vm.searchResult, function(account) {
                  return _c(
                    "div",
                    { key: account.internalId, staticClass: "mb-4" },
                    [
                      _c("v-hover", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var hover = ref.hover
                                return [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "hoverable mb-3",
                                      class: { "on-hover": hover },
                                      attrs: { elevation: hover ? 6 : 2 },
                                      on: {
                                        click: function($event) {
                                          return _vm.onAccountSelected(account)
                                        }
                                      }
                                    },
                                    [
                                      _c("v-card-text", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-center mb-3"
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-2",
                                                staticStyle: {
                                                  "font-size": "30px"
                                                }
                                              },
                                              [_vm._v("account_circle")]
                                            ),
                                            _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "font-weight-regular ma-0"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      account.customerName
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-center mb-3"
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-2",
                                                staticStyle: {
                                                  "font-size": "30px"
                                                }
                                              },
                                              [_vm._v("local_shipping")]
                                            ),
                                            _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "font-weight-light ma-0"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getAddress(account)
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "d-flex align-center"
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-2",
                                                staticStyle: {
                                                  "font-size": "27px"
                                                }
                                              },
                                              [_vm._v("tag")]
                                            ),
                                            _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "font-weight-light ma-0"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(account.internalId) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                }),
                0
              )
        ])
      ]),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "300" },
          model: {
            value: _vm.isSwitchingAccount,
            callback: function($$v) {
              _vm.isSwitchingAccount = $$v
            },
            expression: "isSwitchingAccount"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-3" },
            [
              _c(
                "v-card-text",
                { staticClass: "d-flex flex-column align-center" },
                [
                  _c("p", [_vm._v("Switching account...")]),
                  _c("v-progress-circular", {
                    attrs: { width: "2", indeterminate: "" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }