/**
 * App Entry File
 * Copyright 2018. All Rights Reserved
 */
/* eslint-disable */
import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue';
import vuetify from '@/plugins/vuetify'
import VuetifyMoney from '@/plugins/vuetify-money.js';
import * as VueGoogleMaps from 'vue2-google-maps'
import Nprogress from 'nprogress'
import VueI18n from 'vue-i18n'
import Snotify, { SnotifyPosition } from 'vue-snotify'
import InstantSearch from 'vue-instantsearch'
import VueMoment from 'vue-moment'
//import App from './App.vue'
import { store } from './store/store'
import AppConfig from 'Constants/AppConfig'
import { MsalService } from './services/Msal.service'
import { NumberFormatterService } from './services/NumberFormatter.service';
//// localisation messages
import messages from './lang'

// global components
import GlobalComponents from './globalComponents'

//router 
import router from './router'

// all css files included
import './lib/EmbryoCss';
import './assets/css/global.css';

import { connection as indexerHubConnection } from './hubs/IndexerHub';

// Alliging Position for the toaster
const options = {
	toast: {
		position: SnotifyPosition.rightTop
	}
}

//plugins
Vue.use(VueMoment)
Vue.use(InstantSearch)
Vue.use(VueI18n)
Vue.use(Snotify, options)
Vue.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
		libraries: "places"
	}
});

Vue.use(GlobalComponents)

// router navigation guards
router.beforeEach((to, from, next) => {
	Nprogress.start();
	next();
})

router.afterEach(() => {
	Nprogress.done();
	setTimeout(() => {
		const contentWrapper = document.querySelector("html");
		if (contentWrapper !== null) {
			contentWrapper.scrollTop = 0;
		}
	}, 200);
})


// creating a instance of vue localisation module
const i18n = new VueI18n({
	locale: store.getters.selectedLocale.locale,
	messages,
	silentFallbackWarn: false,
	silentTranslationWarn: false,
	warnHtmlInMessage: "off",
});

Vue.prototype.$msal = new MsalService();
Vue.prototype.$numformat = new NumberFormatterService();

Vue.config.productionTip = false;
Vue.config.disableNoTranslationWarning = true;

new Vue({
	store,
	router,
	i18n,
	vuetify,
	beforeCreate() {
		Vue.$snotify = this.$snotify;
		Vue.$router = this.$router;
	},
	render: h => h(App)
}).$mount('#app')

//new Vue({
//	render: h => h(App)
//}).$mount('#app');
