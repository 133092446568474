<template>
  <div class="emb-card pa-4">
    <div class="table-responsive">
      <v-data-table
        :headers="headers"
        :items="peoplePaginatedList.items"
        :server-items-length="peoplePaginatedList.totalCount"
        :options.sync="listViewOptions"
        :loading="isLoading"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50],
        }">
        <template v-slot:item.action="{ item }">
          <v-btn small icon class="primary--text" @click="openInvitationEmailDialog(item)">
            <v-icon>email</v-icon>
          </v-btn>
          <v-btn small icon class="primary--text" :to="'/admin-panel/person-view/' + item.personId">
            <v-icon>visibility</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>

    <v-dialog v-model="isShowingInvitationEmailDialog" max-width="400">
      <v-card>
        <v-card-text>
          <h5 class="pt-4">Do you want to send an invitation email to {{lastSelectedPerson.firstName}} {{lastSelectedPerson.lastName}}?</h5>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="primary" :disabled="isSendingInvitationEmail" :loading="isSendingInvitationEmail" @click="sendInvitationEmail(lastSelectedPerson)">Yes</v-btn>
          <v-btn color="error" :disabled="isSendingInvitationEmail" @click="isShowingInvitationEmailDialog = false">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PeopleItemsListView from '../People/PeopleItemsListView';
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {

  props: ["account"],
  
  data() {
    return {
      lastSelectedPerson: {},
      isShowingInvitationEmailDialog: false,
      isSendingInvitationEmail: false,
      isLoading: false,

      headers: [
        {
          text: "Employee ID",
          sortable: true,
          value: "employeeId",
        },
        {
          text: "Employee Code",
          sortable: true,
          value: "employeeCode",
        },
        {
          text: "Name",
          sortable: true,
          value: "fullName",
        },
        {
          text: "Position Title",
          sortable: true,
          value: "positionTitle",
        },
        {
          text: "Route ID",
          sortable: true,
          value: "routeId",
        },
        {
          text: "",
          sortable: false,
          value: "action",
        },
      ],
      listViewOptions: {
        page: 1,
        itemsPerPage: 10,
      },
      nameRules: [
        v => !!v || "Name is required"
      ],
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+/.test(v) || "E-mail must be valid"
      ],
    };
  }, // data

  computed: {
    ...mapGetters({
      peoplePaginatedList: 'team/getTeam',
    }),
  }, // computed

  mounted() {
    this.getTeam(this.account.internalId);
  }, // mounted

  methods: {
    ...mapMutations({
      setSearchParamPageSize: 'team/SET_SEARCH_PARAM_PAGE_SIZE',
      setSearchParamPageNumber: 'team/SET_SEARCH_PARAM_PAGE_NUMBER',
      setSearchParamSortField: 'team/SET_SEARCH_PARAM_SORT_FIELD',
      setSearchParamSortDirection: 'team/SET_SEARCH_PARAM_SORT_DIRECTION',
    }),
    ...mapActions({
      getTeam: 'team/get',
      sendInvitationEmailRequest: 'email/sendInvitationEmail'
    }),

    openInvitationEmailDialog(person) {
      this.lastSelectedPerson = person;
      this.isShowingInvitationEmailDialog = true;
    },

    sendInvitationEmail(person) {
      this.isSendingInvitationEmail = true;

      this.sendInvitationEmailRequest(person.emailAddress)
      .then(() => {
        this.isShowingInvitationEmailDialog = false;
        this.$snotify.success("Email has been sent", {
          closeOnClick: false,
          pauseOnHover: true,
          timeout: 2000,
        });
      })
      .catch(error => {
        this.$snotify.success(`Could not send email: ${error}`, {
          closeOnClick: false,
          pauseOnHover: true,
          timeout: 4000,
        });
      })
      .finally(() => this.isSendingInvitationEmail = false);
    },
  }, // methods

  watch: {
    listViewOptions: {
      handler() {
        this.setSearchParamPageSize(this.listViewOptions.itemsPerPage);
        this.setSearchParamPageNumber(this.listViewOptions.page);

        let sortField = this.listViewOptions.sortBy[0];
        let sortDesc = this.listViewOptions.sortDesc[0];

        if (sortField) {
          this.setSearchParamSortField(sortField);
          this.setSearchParamSortDirection(sortDesc? 'DESC' : 'ASC');
        }

        this.isLoading = true;
        this.getTeam(this.account.internalId).finally(() => this.isLoading = false);
      },
    },
  }, // watch

  components: {
    PeopleItemsListView,
  }, // components
};
</script>
