var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.paginatedList
    ? _c(
        "div",
        { staticClass: "table-responsive" },
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.paginatedList.items,
              "server-items-length": _vm.paginatedList.totalCount,
              options: _vm.listViewOptions,
              loading: _vm.isLoading,
              "footer-props": { "items-per-page-options": [10, 20, 30, 40, 50] }
            },
            on: {
              "update:options": function($event) {
                _vm.listViewOptions = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.action",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary--text",
                          attrs: {
                            small: "",
                            icon: "",
                            to: "/admin-panel/person-edit/" + item.personId
                          }
                        },
                        [_c("v-icon", [_vm._v("edit")])],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary--text",
                          attrs: {
                            small: "",
                            icon: "",
                            to: "/admin-panel/person-view/" + item.personId
                          }
                        },
                        [_c("v-icon", [_vm._v("visibility")])],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              1143797288
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }