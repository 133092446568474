/**
* VueShop Store
*/

import Vue from 'vue'
import Vuex from 'vuex'

// modules
import ecommerce from './modules/ecommerce';
import sidebar from './modules/sidebar';
import settings from './modules/settings';
import payment from './modules/payment';
import blog from './modules/blog';
import productDetail from './modules/productDetail';
import products from './modules/products';
import accounts from './modules/accounts';
import productImages from './modules/productImages';
import search from './modules/search';
import geographic from './modules/geographic';
import session from './modules/session';
import catalog from './modules/catalog';
import suppliers from './modules/suppliers';
import order from './modules/orders';
import ordersAdmin from './modules/orders/admin';
import people from './modules/people';
import me from './modules/me';
import tag from './modules/tags';
import fabmenu from './modules/fabmenu';
import types from './modules/types';
import licenses from './modules/licenses';
import stockGroups from './modules/stockGroups';
import stockHoldings from './modules/stockHoldings';
import email from './modules/email';
import team from './modules/team';
import taggedStockItems from './modules/taggedStockItems';
import licenseTypes from './modules/licenseTypes';
import customerStockGroupEligibility from './modules/customerStockGroupEligibility';
import specialDeals from './modules/specialDeals';
import productSearch from './modules/productSearch';
import imageType from './modules/imageType';
import tradingPartner from './modules/tradingPartners';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        ecommerce,
        sidebar,
        settings,
        payment,
        blog,
        products,
        productDetail,
        accounts,
        productImages,
        search,
        geographic,
        session,
        catalog,
        suppliers,
        order,
        ordersAdmin,
        people,
        me,
        tag,
        fabmenu,
        types,
        licenses,
        stockGroups,
        stockHoldings,
        email,
        team,
        taggedStockItems,
        licenseTypes,
        customerStockGroupEligibility,
        specialDeals,
        productSearch,
        imageType,
        tradingPartner
    },
});
