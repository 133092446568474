<template>
    <div>
        <admin-panel-header
            title="Stock Groups"
            icon="group_work"
            :addButton="false"
            @search="onSearch">
        </admin-panel-header>

        <!-- refresh button -->
        <div class="d-flex justify-end">
            <v-btn small text @click="onRefreshClicked">
                Refresh
                <v-icon right>refresh</v-icon>
            </v-btn>
        </div>

        <stock-groups-list-view
            :paginatedList="stockGroupsPaginatedList"
            :isLoading="isLoading"
            :searchParams="searchParams"
            @search-params-changed="onSearchParamsChanged">
        </stock-groups-list-view>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import AdminPanelHeader from '../AdminPanelHeader';
import StockGroupsListView from './StockGroupsListView';

export default {
    
    data: () => ({
        isLoading: false,
        searchValue: undefined,
    }), // data

    computed: {
        ...mapGetters({
            stockGroupsPaginatedList: 'stockGroups/getStockGroups',
            searchParams: 'stockGroups/getSearchParams',
        }),
    }, // compued

    mounted() {
        console.log('Search params are', this.searchParams);
        this.searchValue = this.searchParams.searchValue;

    }, // mounted

    methods: {
        ...mapMutations({
            setSearchValue: 'stockGroups/SET_SEARCH_PARAM_SEARCH_VALUE',
            setSearchParams: 'stockGroups/SET_SEARCH_PARAMS',
            resetSearchParams: 'stockGroups/RESET_SEARCH_PARAMS',
        }),

        ...mapActions({
            getStockGroups: 'stockGroups/get',
        }),

        executeSearch() {
            this.isLoading = true;
            this.setSearchValue(this.searchValue);
            this.getStockGroups().finally(() => this.isLoading = false);
        },

        onRefreshClicked() {
            this.executeSearch();
        },

        onSearch(searchValue) {
            console.log(searchValue);
            this.searchValue = searchValue;
            this.executeSearch();
        },

        onSearchParamsChanged(searchParams) {
            this.setSearchParams(searchParams);
            this.executeSearch();
        },
    }, // methods

    components: {
        AdminPanelHeader,
        StockGroupsListView,
    }, // components
}
</script>
