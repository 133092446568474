var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "pa-4 mb-4" },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "d-flex align-center justify-center justify-sm-start",
                  attrs: { cols: "12", sm: "3" }
                },
                [
                  _c("v-icon", { staticClass: "mr-2" }, [_vm._v("person")]),
                  _c("h5", { staticClass: "ma-0" }, [_vm._v("People")]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "d-sm-none",
                      attrs: {
                        color: "error",
                        icon: "",
                        to: "/admin-panel/person-add"
                      }
                    },
                    [_c("v-icon", [_vm._v("add")])],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-center align-center",
                  attrs: { cols: "12", sm: "6" }
                },
                [
                  _c("v-text-field", {
                    staticClass: "mr-4",
                    attrs: { label: "Search People" },
                    on: { change: _vm.executeSearch },
                    model: {
                      value: _vm.searchValue,
                      callback: function($$v) {
                        _vm.searchValue = $$v
                      },
                      expression: "searchValue"
                    }
                  }),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.executeSearch }
                    },
                    [_vm._v("Search")]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-end align-center",
                  attrs: { cols: "12", sm: "3" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "d-none d-sm-flex",
                      attrs: { color: "error", to: "/admin-panel/person-add" }
                    },
                    [_vm._v(" Add user "), _c("v-icon", [_vm._v("add")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", [
        _c(
          "div",
          { staticClass: "d-flex justify-end" },
          [
            _c("v-switch", {
              staticClass: "ma-0 pa-0",
              attrs: { label: "Show All People" },
              on: { change: _vm.onShowAllPeopleSwitched },
              model: {
                value: _vm.searchParams.showAllPeople,
                callback: function($$v) {
                  _vm.$set(_vm.searchParams, "showAllPeople", $$v)
                },
                expression: "searchParams.showAllPeople"
              }
            })
          ],
          1
        ),
        !_vm.searchParams.showAllPeople
          ? _c("p", { staticClass: "text-right" }, [
              _vm._v(
                "Showing people from " +
                  _vm._s(_vm.currentLocation.locationName)
              )
            ])
          : _vm._e()
      ]),
      _c("people-items-list-view", {
        attrs: {
          isLoading: _vm.isLoading,
          paginatedList: _vm.peoplePaginatedList,
          searchParams: _vm.searchParams
        },
        on: { "search-params-changed": _vm.onSearchParamsChanged }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }