var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex mb-6" },
        [
          _c(
            "router-link",
            {
              staticClass: "font-weight-medium",
              attrs: { to: "/admin-panel/partners" }
            },
            [_vm._v("Back")]
          )
        ],
        1
      ),
      _vm.isLoadingPartner
        ? _c(
            "div",
            { staticClass: "center-x" },
            [
              _c("v-skeleton-loader", {
                attrs: { type: "text", width: "100%" }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Trading partner")]),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Company Id", required: "" },
                        model: {
                          value: _vm.partner.companyId,
                          callback: function($$v) {
                            _vm.$set(_vm.partner, "companyId", $$v)
                          },
                          expression: "partner.companyId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Partner description", required: "" },
                        model: {
                          value: _vm.partner.x12TradingPartnerDescription,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.partner,
                              "x12TradingPartnerDescription",
                              $$v
                            )
                          },
                          expression: "partner.x12TradingPartnerDescription"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Partner Quanlifier", required: "" },
                        model: {
                          value:
                            _vm.partner.x12interchangeTradingPartnerQualifier,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.partner,
                              "x12interchangeTradingPartnerQualifier",
                              $$v
                            )
                          },
                          expression:
                            "partner.x12interchangeTradingPartnerQualifier"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Partner Identifier", required: "" },
                        model: {
                          value:
                            _vm.partner.x12interchangeTradingPartnerIdentifier,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.partner,
                              "x12interchangeTradingPartnerIdentifier",
                              $$v
                            )
                          },
                          expression:
                            "partner.x12interchangeTradingPartnerIdentifier"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Partner Group Identifier",
                          required: ""
                        },
                        model: {
                          value:
                            _vm.partner
                              .x12interchangeTradingPartnerGroupIdentifier,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.partner,
                              "x12interchangeTradingPartnerGroupIdentifier",
                              $$v
                            )
                          },
                          expression:
                            "partner.x12interchangeTradingPartnerGroupIdentifier"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Partner Erp Identifier",
                          required: ""
                        },
                        model: {
                          value:
                            _vm.partner
                              .x12interchangeTradingPartnerErpidentifier,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.partner,
                              "x12interchangeTradingPartnerErpidentifier",
                              $$v
                            )
                          },
                          expression:
                            "partner.x12interchangeTradingPartnerErpidentifier"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Partner Type Identifier",
                          required: ""
                        },
                        model: {
                          value: _vm.partner.x12interchangeTradingPartnerTypeId,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.partner,
                              "x12interchangeTradingPartnerTypeId",
                              $$v
                            )
                          },
                          expression:
                            "partner.x12interchangeTradingPartnerTypeId"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { justify: "end" } },
                [
                  _c(
                    "v-col",
                    { attrs: { "align-self": "end", cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.savePartner()
                            }
                          }
                        },
                        [_vm._v("Save")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }